const i18n = require('@/lang/index')

module.exports = {
  info: {
    url: 'www.alphatick.com',
    url2: 'www.alphatick.com/cn',
    url3: 'www.alphatick.com/id',
    url4: 'www.alphatick.com/it',
    url5: 'www.alphatick.com/es',
    name: 'AlphaTick LLC',
    email: 'support@alphatick.com',
    withdrawalEmail: 'support@alphatick.com',
    phone: '+46.731298066',
  },
  withdrawSetup: {
    cancelWithdraw: true,
  },
}
