export default {
  CUID: 0,
  lang: 'en_US',
  loginStatus: '',
  userName: '',
  email: '',
  phone: '',
  createTime: '',
  address: '',
  cpUrl: '',
  accessToken: '',
  countryCode: '',
  regulator: null,
  autoNotifyStatus: true,
  isSubUser: false,
  subPermission: [],
  subUserSwitch: false,
  subEmail: '',
  customerDataPermission: false,
  ibDataPermission: false,
  operateAmountPermisison: false,
  sessionId: null,
  sessionInternalId: null,
}
