import { apiTo_login_by_token, apiGet_cp_url } from '@/resource'
import { handleError } from './handleError'
import store from '@/store'
import router from '@/router'
import { getRegulator } from '@/util/regulator'
import { getCookies, setCookies } from '@/util/cookies'
import config from '@/config'
import { createSecureURL } from '@/util/secureURLUtils'

export default {
  signOut(method = 'backToClientPortal', path = '') {
    const accessToken = store.state.common.accessToken
    store.commit('common/reset')
    store.commit('behavior/reset')
    store.commit('regulator/reset')
    store.commit('common/setSessionId', { sessionId: null, sessionInternalId: null })
    setCookies('token', '')
    this.getCpUrl(method, accessToken, path)
  },
  loginByToken(accessToken, pk) {
    apiTo_login_by_token({
      headers: {
        accessToken: accessToken,
        pk: pk,
      },
    })
      .then(resp => {
        if (resp.data.code === 0) {
          store.dispatch('regulator/actionGetInformation')
          store.commit('common/setAccessToken', resp.data.data.accessToken)
          setCookies('token', resp.data.data.accessToken, 30)
          store.commit('common/setLoginStatus', true)
          store.commit('common/setUserName', resp.data.data.userName)
          store.commit('common/setEmail', resp.data.data.email)
          store.commit('common/setPhone', resp.data.data.phone)
          store.commit('common/setCreateTime', resp.data.data.date)
          store.commit('common/setAddress', resp.data.data.address)
          store.commit('common/setLang', resp.data.data.language)
          store.commit('common/setCountryCode', resp.data.data.countryCode)
          store.commit('common/setRegulator', getRegulator(resp.data.data.regulator))
          store.commit('common/setIsSubUser', resp.data.data.isSubUser ? resp.data.data.isSubUser : false)
          store.commit('common/setSubEmail', resp.data.data.isSubUser ? resp.data.data.subEmail : '')
          store.commit('common/setSubUserSwitch', resp.data.data.isSubUser ? false : resp.data.data.subUserSwitch)
          store.commit('common/setSubPermission', resp.data.data.permissionIds)
          setCookies('cpUrl', resp.data.data.url, 60 * 24 * 30)
          router.push('/home')
        } else if (resp.data.code === 1500) {
          router.push('/home')
        }
      }, handleError)
      .catch(err => {
        router.go(-1)
      })
  },
  getCpUrl(method, accessToken, path = '') {
    const cpUrl = getCookies('cpUrl')
    if (method === 'logout') store.commit('common/setAutoNotifyStatus')
    if (cpUrl) this.goToCp(method, cpUrl, accessToken, path)
    else
      apiGet_cp_url().then(resp => {
        setCookies('cpUrl', resp.data.data, 60 * 24 * 30)
        this.goToCp(method, resp.data.data, accessToken, path)
      }, handleError)
  },
  async goToCp(method = 'backToClientPortal', cpUrl = config.cpUrl, accessToken = '', path) {
    const baseUrl = process.env.NODE_ENV == 'development' ? 'http://localhost:8090/' : cpUrl
    let cpPath = ''
    if (path != null) cpPath = `&path=${path}`

    if (method == 'logout') {
      window.location = baseUrl + `logout?lang=${store.state.common.lang}`
    } else {
      try {
        const secureURL = await createSecureURL(baseUrl, accessToken)
        window.location = secureURL + `${cpPath}`
      } catch (err) {
        console.log(err)
      }
    }
  },
}
