export default {
  en: 'Inglés',
  zh: '中文',
  home: {
    RebateAccountNumber: 'Número de cuenta de comisíon',
    totalCommission: 'comisión total',
    APPLY_FOR_A_REBATE: 'SOLICITAR COMISÍON',
    availableBalance: 'Saldo disponible',
    WITHDRAW_REBATE: 'RETIRAR',
    TRANSFER_REBATE_INTO_YOUR_TRADING_ACCOUNT: 'TRANSFERIR',
    UPDATE: 'ACTUALIZAR',
    REBATE: 'COMISÍON',
    TOTAL_VOLUME: 'VOLUMEN TOTAL',
    NET_FUNDING: 'FINANCIAMIENTO NETO',
    DEPOSITS: 'DEPOSITOS',
    WITHDRAW: 'RETIRAR',
    OPEND_ACCOUNT: 'CUENTA ABIERTA',
    PERFORMANCE: 'ACTUACIÓN',
    ACCOUNT_MANAGER: 'GERENTE DE CUENTAS',
    MOST_TRADED_INSTRUMENTS: 'INSTRUMENTOS MÁS COMERCIADOS',
    TOP_PERFORMING_ACCOUNTS_FOR_THIS_MONTH: 'CUENTAS DE MAYOR RENDIMIENTO PARA ESTE MES',
    RECENTLY_OPENED_ACCOUNTS: 'CUENTAS RECIENTEMENTE ABIERTAS',
    Account: 'Cuenta',
    Name: 'Nombre',
    Volume: 'Volumen',
    Rebate: 'COMISÍON',
    Balance: 'balance ',
    ContactClient: 'Comuníquese con el cliente',
    applyRebateSuccessTip:
      'Felicitaciones, hemos recibido su solicitud de reembolso. | {amount} se transferirá a la cuenta de reembolso {account} dentro de 1 día hábil. | Puede retirar o transferir fondos una vez que se procese su reembolso.',
    blacklist:
      'Esta cuenta no puede efectuar reembolsos en este momento, por favor, contacte con con su gestor de cuenta.',
  },
  liveChat: { header: 'Chat en vivo' },
  ibAccounts: { ibAccount: 'Cuenta IB', subIbs: 'Cuenta Sub IB' },
  withdraw: {
    disclaimer:
      '<li>Descargo de responsabilidad:</li>\n      <li>Por favor, tenga en cuenta que el tipo de cambio para vender criptodivisas a monedas fiduciarias lo determina únicamente Banxa, un procesador de pagos tercero. La marca Alphatick no tiene influencia ni control sobre el tipo de cambio ofrecido por Banxa. Por lo tanto, cualquier fluctuación o modificación en el tipo de cambio está fuera de nuestro control y es atribuible únicamente a las políticas y condiciones de mercado de Banxa.</li>\n      <li>Le recomendamos que revise el tipo de cambio y las comisiones o cargos asociados que ofrece Banxa antes de continuar con la transacción. Además, tenga en cuenta que Alphatick no es responsable de las pérdidas financieras o discrepancias provocadas por el uso de los servicios de Banxa o de cualquier otro procesador de pagos tercero.</li>\n      <li>Gracias por su comprensión y cooperación.</li>\n     ',
    default: {
      selectCC: 'Seleccione la tarjeta de crédito',
      selectBA: 'Seleccione la cuenta bancaria',
      anotherCC: 'Introduzca la tarjeta de crédito manualmente',
      anotherBA: 'Añadir cuenta bancaria',
      remember: 'Recuerda mi cuenta',
      failed: 'Error al retirar. Por favor, inténtelo de nuevo más tarde',
      tokenExpired: 'El token ha expirado',
    },
    transfer: {
      info: '{platform} reembolsará la comisión bancaria cobrada por <u>UNA</u> retirada al mes. Las retiradas adicionales durante el mes tendrán en una comisión bancaria de 20 unidades de su divisa de retirada.',
      label: {
        upload:
          'Por favor, envíe una copia de su extracto bancario (actualizado con una antigüedad no superior a 3 meses)',
      },
      formValidation: { upload: 'Por favor, envíe un extracto bancario' },
    },
    alert: {
      title: 'Antes de enviar su solicitud de retirada, por favor, tenga en cuenta lo siguiente:',
      desc1:
        'Debido a las leyes de AML, el método que utilice para retirar debe ser el mismo que el utilizado para su depósito. Si la cantidad retirada es inferior a {units} unidades de la moneda base de su cuenta, el banco cobrará una comisión de {charge} unidades por su manejo. ',
    },
    cryptoAlert:
      'Por favor, tenga en cuenta que no podemos depositar o retirar BUSDT. Por favor, asegúrese que la dirección y la criptodivisa coinciden con la cadena y divisa disponibles o podría resultar en una pérdida de fondos.',
    REBATE_WITHDRAW_REQUEST: 'Solicitud de retirada de la Comisión.',
    PleaseSelectDesiredWithdrawMethod: 'Seleccione el método de retiro deseado',
    BankName: 'Nombre del banco',
    bankCode: 'Código bancario',
    bankBranchCode: 'Código de sucursal',
    BankAccountsName: 'Nombre de cuentas bancarias',
    FasaPayAccountName: 'Nombre de la cuenta de FasaPay',
    AustralianBankName: 'Nombre del banco australiano',
    BankAccountNumber: 'Número de cuenta bancaria',
    bankAccountCurrency: 'Divisa de la cuenta bancaria',
    FasaPayAccountNumber: 'Número de cuenta de FasaPay',
    BankAccountBeneficiary: 'Beneficiario de la cuenta bancaria',
    BSB: 'BSB',
    skrillEmail: 'Correo electrónico de Skrill',
    NetellerEmail: 'Correo electrónico de Neteller',
    BitcoinEmail: 'Dirección de la billetera de Bitcoin',
    USDTEmail: 'Dirección de la billetera de Tether',
    perfectMoneyEmail: 'Correo electrónico de Perfect Money',
    BankBranch: 'Sucursal bancaria',
    Province: 'Provincia',
    City: 'Ciudad',
    BankAddress: 'Dirección del banco',
    AccountNumberIBAN: 'Número de cuenta / IBAN',
    BankBeneficiaryName: 'Nombre del beneficiario del banco',
    BankAccountBeneficiaryName: 'Nombre del beneficiario de la cuenta bancaria',
    AccountHoldersAddress: 'Dirección del titular de cuenta',
    SelectWithdrawCard: 'Seleccione su tarjeta de retiro',
    Swift: 'SWIFT',
    SwiftAud: 'Swift (cuentas sin AUD)',
    ABA_SortCodeABA: 'ABA / Código de clasificación',
    name: 'Nombre',
    phone: 'Número de teléfono',
    address: 'Dirección',
    email: 'Correo electrónico',
    ImportantNotes: 'Notas importantes',
    YourPaymentGreaterThanAvailableBalance: 'Su pago es mayor que el saldo disponible',
    CannotUseMoreThanTwoDecimalValues:
      'No puede usar números y números negativos con más de dos lugares decimales',
    YourPaymentIsBeingProcessed: 'Su solicitud de retiro ha sido enviada',
    Itcannotbe0: 'No puede ser 0',
    noCardWarn:
      'No tiene ninguna tarjeta disponible para realizar retiros, primero vincule su tarjeta en Detalles del retiro',
    amtLarger: 'La cantidad no puede ser nula y debe ser mayor o igual a $ {minLimit}',
    blackList:
      'Desafortunadamente actualmente no puede solicitar una retirada. Por favor, póngase en contacto con {supportEmail} para más información',
    NetellerChargeAlert: 'Neteller cobra una tarifa de transacción del 2% por cada retiro.',
    FasaPayChargeAlert: 'FasaPay cobra una tarifa de transacción del 0,5% por cada retiro.',
    SkrillChargeAlert: 'Skrill cobra una tarifa de transacción del 1% por cada retiro.',
    status: {
      withdrawalSubmitted: 'Presentada',
      withdrawalCanceled: 'Cancelado',
      withdrawalAuditing: 'Procesando',
      withdrawalRejected: 'Rechazado',
      withdrawalAccepted: 'Procesando',
      withdrawalPayFailed: 'Ha fallado',
      withdrawalSuccess: 'Procesada',
      withdrawalFailed: 'Ha fallado',
      withdrawalPending: 'Pendiente',
      withdrawalPartialFailed: 'Ha fallado',
      transferSubmitted: 'Procesando',
      transferSuccess: 'Procesada',
      transferRejected: 'Rechazado',
      transferFailed: 'Ha fallado',
      transferProcessing: 'Procesando',
      withdrawalReversedfailed: 'Fracaso Revertido',
    },
    formErrorMsg: {
      bankName: 'Se requiere el nombre del banco',
      bankCodeReq: 'Se necesita el código bancario',
      bankBranchCodeReq: 'Se necesita el código de sucursal',
      bsb: 'Se requiere BSB',
      beneName: 'Se requiere el nombre del beneficiario del banco',
      accNum: 'Se requiere el número de cuenta bancaria',
      swift: 'Se requiere Swift',
      bankAddress: 'Se requiere la dirección del banco',
      holderAddress: 'Se requiere la dirección del titular',
      amt: 'Se requiere la cantidad',
      firstSixDigits: 'Ingrese los primeros 6 dígitos',
      lastFourDigits: 'Ingrese los últimos 4 dígitos',
      branchName: 'El nombre de la sucursal es obligatorio',
      email: 'correo electronico es requerido',
      accName: 'Se requiere el nombre de la cuenta',
      province: 'La provincia del banco es obligatoria',
      city: 'La ciudad del banco es obligatoria',
      bankCard: 'Por favor seleccione una tarjeta',
      emailFormat: 'Please input correct email address',
      bitcoin: 'La dirección de la cartera de Bitcoin es obligatoria',
      bitcoinAddressLength:
        'La dirección de la cartera de Bitcoin debe tener entre 26 y 42 caracteres',
      usdt: 'La dirección de la cartera de USDT es obligatoria',
      dynamicReq: 'El campo {fieldname} es obligatorio',
      beneficiaryNameReq: 'Se requiere el nombre del beneficiario del banco',
    },
    beneficiaryName: 'Nombre del beneficiario',
  },
  rebate: { status: { processed: 'Procesada', processing: 'Procesando', rejected: 'Rechazado' } },
  transfer: {
    REBATE_TRANSFER_REQUEST: 'SOLICITUD DE TRANSFERENCIA DE COMISIÓN',
    transferAmountIsGreaterThanAvailableBalance:
      'El monto de su transferencia es mayor que el saldo disponible',
    yourRebateTransferHasBeenProcessed: 'su transferencia de reembolso ha sido enviada',
    PleaseEnterTransferInformation: 'Ingrese información de transferencia precisa',
    tradingAccount: 'Cuenta de operaciones',
    rebateAccount: 'Cuenta de comisíon',
    account: 'Cuenta',
  },
  fileUploader: {
    supportedFormat: 'Tipos de archivo compatibles: png, jpg, jpeg, bmp, pdf, doc, docx',
    supportedFormatCustom: 'Tipos de archivo compatibles: {files}',
    maxSize: 'Tamaño máximo de archivo subido: {maxSize} MB',
    maxFileNum: 'Número máximo de archivos: {maxFileNum}',
    failedFileExtension: 'Cargue el tipo de archivo correcto',
  },
  common: {
    liveChat: { desc: '<li><p>¿Necesita ayuda?</p><span>Chat en vivo</span></li>' },
    lang: {
      english: 'Inglés',
      chinese: 'chino',
      french: 'francés',
      thai: 'tailandés',
      german: 'alemán',
      spanish: 'Español',
      malay: 'malayo',
      viet: 'vietnamita',
      indo: 'indonesio',
      arabic: 'Arábica',
    },
    button: { confirm: 'CONFIRMAR', cancel: 'CANCELAR', selectFile: 'Seleccionar archivo' },
    withdrawChannel: {
      banktransfer: 'Transferencia bancaria',
      aus: 'Transferencia bancaria - Australia',
      int: 'Transferencia bancaria - Internacional',
      chn: 'Transferencia bancaria - China',
      skrill: 'Skrill',
      skrill_neteller: 'Skrill / Neteller',
      neteller: 'Neteller',
      unionpay: 'UnionPay',
      fasapay: 'FasaPay',
      thailandinstantbankwiretransfer: 'Transferencia bancaria instantánea de Tailandia',
      malaysiainstantbankwiretransfer: 'Transferencia bancaria instantánea de Malasia',
      indonesiainstantbankwiretransfer: 'Transferencia bancaria instantánea de Indonesia',
      philippinesinstantbankwiretransfer: 'Transferencia bancaria instantánea de Philippine',
      banktransferbpaypolipay: 'Transferencia bancaria / BPay / PoliPay',
      banktransferbpaypolipayaustralia: 'Transferencia bancaria / BPay / PoliPay (Australia)',
      banktransferbpaypolipayinternational:
        'Transferencia bancaria / BPay / PoliPay (internacional)',
      cryptocurrencybitcoin: 'Cryptocurrency-Bitcoin',
      cryptocurrencyusdt: 'Cryptocurrency-USDT',
      vietnaminstantbankwiretransfer: 'Transferencia bancaria instantánea de vietnamita',
      laosinstantbankwiretransfer: 'Transferencia bancaria instantánea de Laos',
      indiainstantbanktransfer: 'Transferencia bancaria instantánea de India',
      brazilbanktransfer: 'Transferencia bancaria instantánea de Brasil',
      mexicobanktransfer: 'Transferencia bancaria instantánea de México',
      internetbankingmexico: 'Banca online (México)',
      internetbankingbrazil: 'Banca online (Brasil)',
      transfer: 'Transferencia a cuentas comercial',
      hongkongbanktransfer: 'Transferencia bancaria local de Hong Kong',
    },
    country: { international: 'Internacional', australia: 'Australia' },
    field: { country_region: 'País / Región', yes: 'Sí' },
    keys: {
      SUBMIT: 'ENVIAR',
      FROM: 'DESDE',
      UPDATE: 'ACTUALIZAR',
      TO: 'HASTA',
      DATE: 'FECHA',
      pleaseSelect: 'Por favor seleccione',
      amount: 'Cantidad',
      rebateAccount: 'Cuenta de comisíon',
      comingSoon: 'En breve',
      tradingHistory: 'HISTORIAL COMERCIAL',
      noData: 'Sin datos',
      NAME: 'Nombre',
      EMAIL: 'Correo electrónico',
      PHONE: 'Número de teléfono',
      ACCTYPE: 'TIPO DE CUENTA',
      PLATFORM: 'PLATAFORMA',
      BASECURRENCY: 'MONEDA BASE',
      DATERANGE: 'RANGO DE FECHAS',
      STATUS: 'Status',
      DEST: 'DESTINO',
      ACCNUM: 'NÚMERO DE CUENTA',
      BALANCE: 'SALDO ',
      NONE: 'Ninguno',
      ALL: 'Todas',
      PROFIT: 'Beneficio',
      MARGINLEVEL: 'Nivel de margen(%)',
      ACCOUNTEQUITY: 'Equidad de cuentas',
      CREDIT: 'Crédito',
      createTime: 'Hora de envío',
      lastUpdateTime: 'Tiempo procesado',
    },
    dateRange: {
      today: 'Hoy',
      yesterday: 'Ayer',
      lastSevenDays: 'Los últimos 7 días',
      lastThirtyDays: 'Últimos 30 días',
      weekToDate: 'Semana hasta la fecha',
      monthToDate: 'Mes hasta la fecha',
      quarterToDate: 'Trimestre hasta la fecha',
      previousWeek: 'Semana pasada',
      previousMonth: 'Mes anterior',
      previousQuarter: 'Trimestre anteriorr',
    },
    products: { forex: 'FOREX', commodities: 'COMMODITIES', indices: 'ÍNDICES', crypto: 'CRYPTO' },
  },
  report: {
    rebate: {
      column: {
        name: 'Nombre',
        accountNumber: 'NÚMERO DE CUENTA',
        accountType: 'TIPO DE CUENTA',
        volume: 'Volumen',
        rebate: 'COMISÍON',
        totalRebate: 'COMISÍON TOTAL',
        date: 'FECHA',
        instrument: 'INSTRUMENTO',
        notionalvalue: 'VALOR NOCIONAL',
      },
      dropdown: { allInstruments: 'Todos los instrumentos' },
      others: {
        rebate: 'COMISÍON',
        instrumentsTraded: 'INSTRUMENTOS COMERCIADOS',
        lots: 'Lotes',
        totalRebate: 'Reembolso total',
      },
    },
  },
  transferHistory: {
    payStatus: {
      processing: 'Procesando',
      rejected: 'Rechazado',
      paid: 'Pagado',
      unpaid: 'No pagado',
    },
  },
  ibReport: {
    netFunding: 'FINANCIAMIENTO NETO',
    deposits: 'DEPOSITOS',
    withdraw: 'RETIRAR',
    openedAccs: 'CUENTAS ABIERTAS',
    funding: 'FONDOS',
  },
  lead: { header: 'Leads', ibAccount: 'Cuenta IB', demoAccounts: 'CUENTAS DEMO' },
  pendingAccounts: {
    NOTES: 'Notas',
    errorDoc:
      'Los siguientes solicitantes han completado el formulario de solicitud en línea, pero aún deben proporcionar una prueba de identificación (ID) para finalizar su cuenta.',
    acceptedDocType: 'Las formas de identificación aceptadas incluyen:',
    primaryDoc: 'ID principal',
    idList: [
      'Una copia de su pasaporte internacional válido (página de firma)',
      'Una copia de la tarjeta de identidad nacional emitida por su gobierno',
      'Una copia de su licencia de conducir válida.',
    ],
    address: 'Identificación secundaria',
    addressList: [
      'Una copia de una factura reciente de servicios públicos. Por ejemplo, gas o electricidad que contenga su nombre y dirección residencial (no más de 6 meses)',
      'Una copia del extracto de su tarjeta de crédito o extracto bancario (sin impresiones en línea)',
    ],
    tip: 'Solicite a los solicitantes a proporcionar una copia de UNO de los documentos de identificación primarios y secundarios aceptados a {mailTo}',
  },
  rebatePaymentHistory: {
    transferHistory: 'HISTORIAL DE TRANSFERENCIAS',
    rebateHistory: 'HISTORIAL DE COMISÍON',
    withdrawHistory: 'HISTORIAL DE RETIROS',
  },
  referralLinks: {
    ibAcc: 'Cuenta IB',
    campLang: 'Idioma de la campaña',
    cpy: 'COPIAR',
    alreadyCPY: 'COPIADO!',
    liveAcc: 'Cuenta real',
    demoAcc: 'Cuenta demo',
    homePage: 'Página de inicio',
  },
  settings: {
    header: 'Información de cliente',
    name: 'nombre del cliente',
    emailAdd: 'CORREO ELECTRÓNICO',
    phone: 'Número de teléfono',
    address: 'Direccíon',
  },
  unfundedAcc: {
    neverFundedAccs: 'CUENTAS NUNCA FINANCIADAS',
    prevFundedZero: 'FINANCIADO ANTERIORMENTE (SALDO CERO)',
  },
  header: { bckToCP: 'VOLVER AL PORTAL DEL CLIENTE', logout: 'CERRAR SESIÓN' },
  multiib: { level: 'NIVEL {level}', noib: 'No hay sub-IBs en esta cuenta.' },
  menu: {
    dashboard: 'PANEL DE CONTROL',
    ibReport: 'INFORME IB',
    rebateReport: 'REPORTE DE COMISÍON',
    ibAccs: 'CUENTAS IB',
    iblevel: 'MULTINIVEL IB',
    leads: 'Leads',
    pendingAccs: 'CUENTAS PENDIENTES',
    unfundedAccs: 'CUENTAS NO FINANCIADAS',
    payHistory: 'HISTORIAL DE TRANSACCIONES',
    ibProfile: 'PERFIL IB',
    refLinks: 'ENLACES DE REFERENCIA',
    contactUs: 'CONTÁCTENOS',
    IB_PORTAL: 'PORTAL DE IB',
    FEATURES: 'FUNCIONALIDADES',
  },
  subIb: {
    title: 'Cuenta IB compartida',
    account: 'Cuenta',
    accountNote: 'Máximo 10 cuentas IB compartidas',
    subCreateAcc: 'Agregar',
    subCreateIbAcc: 'Agregar nueva cuenta IB compartida',
    editAccount: 'Editar cuenta IB compartida',
    copied: 'Cuenta IB compartida copiada',
    no: 'Número',
    permission: 'Permiso',
    remark: 'Observación',
    status: 'Estado',
    confirm: 'Confirmar',
    cancel: 'Cancelar',
    noPermission: 'Sin permisos para esta cuenta',
    setting: 'Configuración',
    viewMore: 'Ver más',
    editPassword: 'Restablecer contraseña',
    resetPassword: 'Ingrese la nueva contraseña',
    delete: 'Eliminar',
    emailAddress: 'Dirección de correo electrónico',
    emailFormat: 'Ingrese una dirección de correo electrónico válida',
    permissionTooltip:
      'Puede permitir o no las siguientes autorizaciones para cuentas compartidas:<li>• Datos de la cuenta: Ver páginas de datos de la cuenta (Cuentas IB, Cuentas abiertas recientemente). Los datos se ocultarán si no se permite el permiso acceso. </li><li>• Reporte de datos IB: Ver páginas de datos de IB (Panel de control, IB multinivel: cuenta). Los datos se ocultarán si no se permite el permiso acceso. </li><li>• Permiso de fondos: Operar con fondos IB (Solicitar reembolso, Transferir reembolso, Retirar reembolso),Ver reembolso (informe de reembolso, registros de saldo y fondos). No se pueden realizar operaciones si no se permite el permiso acceso. </li>',
    refresh: 'Actualizar',
    historyEnd: 'Todo el historial ha sido mostrado',
    refreshTips: 'Historial actualizado',
    password: 'Contraseña',
    isActive: 'Habilitado',
    updatedTime: 'Última actualización',
    accWarning: 'Existen 10 cuentas, no se pueden agregar más.',
    isInactive: 'Deshabilitado',
    passwordTooltip:
      '<li>• 8-16 caracteres</li><li>• Mezcla de letras mayúsculas y minúsculas (A-Z, a-z), números (0-9) y caracteres especiales (por ejemplo: !@#S%^&)</li>',
    resetPasswordTooltip:
      'La antigua contraseña será inválida y se restablecerá con la nueva contraseña',
    all: 'Todas',
    chooseAccount: 'Elegir cuenta',
    permissionField: 'Permiso de cuenta IB compartida',
    historyTitle: 'Historial de cuenta compartida',
    device: 'Dispositivo',
    emailExisting: 'El correo electrónico ya existe, por favor intente con otro',
    remarkPlaceholder: 'Puede registrar el propósito de la cuenta o el titular de la cuenta',
    passwordRequired: 'La contraseña es obligatoria',
    deleteDesc:
      'La cuenta IB compartida no podrá iniciar sesión después de la eliminación, ¿desea eliminarla?',
    updateWithdrawalDesc:
      'Después de permitir la autorización de fondos, la cuenta IB compartida puede transferir y retirar el saldo de su cuenta IB principal. ¿Desea permitir esto?',
    loginAction: 'Iniciar sesión',
    applyRebateAction: 'Solicitar reembolso',
    deleteSuccess: 'La cuenta IB compartida ha sido eliminada.',
    enabledSuccess: 'La cuenta IB compartida ha sido habilitada.',
    disabledSuccess: 'La cuenta IB compartida ha sido deshabilitada.',
    addSuccess: 'Cuenta compartida creada',
    modifiedSuccess: 'Modificado con éxito',
    withdrawRebateAction: 'Retirar reembolso: De {from} a {to}, monto {amount}',
    passwordSuccess: 'Restablecimiento de contraseña exitoso',
    transferRebateAction: 'Transferir reembolso: De {from} a {to}, monto {amount}',
    newPwAccFormat:
      'Su contraseña debe tener entre 8 y 16 caracteres y una mezcla de letras (a-z y A-Z), números (0-9) y caracteres especiales como !@#$%^&*',
    clientData: 'Datos de cuentas',
    ibData: 'Reporte de datos IB',
    fundsPermission: 'Permiso de fondos',
  },
  footer: {
    riskWarn:
      'Advertencia de riesgo: operar en Forex y CFD implica un riesgo significativo y puede resultar en la pérdida de su\n    capital. La negociación de productos apalancados puede no ser adecuada para todos los inversores. Antes de operar, tenga en cuenta\n    su nivel de experiencia, objetivos de inversión y busque asesoría financiera independiente si es necesario.\n    Lea nuestros documentos legales en nuestro sitio web y asegúrese de comprender completamente los riesgos antes de realizar cualquier\n    decisiones comerciales.',
  },
  responseMsg: {
    410: 'Error de validación del parámetro',
    411: 'La contraseña no es correcta',
    420: 'El usuario no existe',
    421: 'Restablecimiento de contraseña rechazado',
    490: 'No envíe información de tarjetas duplicadas',
    500: 'Ha ocurrido un error.',
    501: 'Error en su solicitud Por favor, inténtelo de nuevo más tarde.',
    505: 'Error al enviar el archivo',
    520: 'Error al iniciar sesión. Por favor, vuelva a intentarlo.',
    521: '¡Un usuario ya ha iniciado sesión en este dispositivo!',
    522: 'Por favor, inicie sesión de nuevo',
    523: 'Por favor, inicie sesión de nuevo',
    524: 'Desafortunadamente, su dirección de correo electrónico no fue reconocida',
    525: 'El nombre de usuario y la contraseña no son correctos',
    527: 'El usuario no es un IB',
    528: 'El usuario no existe',
    529: 'El nombre de usuario y la contraseña no son correctos',
    540: 'No se pudo encontrar su cuenta de trading',
    541: 'No se pudo encontrar la cuenta de reembolso',
    542: 'No se le permite abrir cuentas adicionales',
    544: 'No puede solicitar una cuenta adicional hasta que se verifique su prueba de identidad',
    550: 'El número de cuenta no es correcto',
    551: 'No tiene suficiente saldo',
    553: 'No puede retirar/transferir debido al saldo de su cuenta. Por favor, póngase en contacto con {email} para más información',
    554: 'La cantidad solicitada es cero o nula',
    562: 'No fue posible procesar su pago, por favor, inténtelo de nuevo. Si el problema persiste, por favor contáctenos por chat, o por correo electrónico.',
    564: 'El país no coincide con el medio de pago',
    565: 'El código bancario no es correcto',
    566: 'Método de pago no encontrado',
    581: 'Elegiste con éxito el ascenso. Por favor, tenga en cuenta que debe hacer un depósito para poder retirar los fondos.',
    584: 'No tiene ninguna comisíon aplicable',
    590: 'No se pudo encontrar la cuenta de su transferencia saliente',
    591: 'No se pudo encontrar la cuenta de su transferencia entrante',
    593: 'No podemos procesar la transferencia de sus fondos desde la cuenta seleccionada porque su cuenta contiene crédito.',
    594: 'No se permiten transferencias de fondos desde esta cuenta',
    1101: 'Existen 10 cuentas, no se pueden agregar más.',
    1102: 'No se puede habilitar, la cuenta se creó como una cuenta real o de demostración.',
    session_timeout:
      'La sesión ha llegado al límite de tiempo, serás redirigido a la página de acceso.',
  },
};
