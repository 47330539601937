<template>
  <el-form-item :label="label" :prop="name" :class="{ finish: openClass }">
    <el-input
      v-on="listeners"
      v-bind="$attrs"
      :id="name"
      auto-complete="new-password"
      :placeholder="placeholder"
      :disabled="disabled"
      :data-testid="name"
    />
    <slot></slot>
  </el-form-item>
</template>

<script>
export default {
  name: 'inputTemplate',
  props: { name: String, label: String, placeholder: String, disabled: Boolean },
  data() {
    return {
      focus: false
    };
  },
  computed: {
    openClass() {
      return this.$attrs.value || this.focus || this.placeholder;
    },
    listeners() {
      return Object.assign({}, this.$listeners, {
        input: event => this.$emit('input', event),
        focus: event => (this.focus = true),
        blur: event => (this.focus = false)
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.el-form-item {
  input {
    position: sticky;
    z-index: 101;
  }

  &.finish /deep/ label {
    font-size: 14px;
    bottom: 45px;
  }
}

/deep/ .el-input__inner:-webkit-autofill,
/deep/ .el-input__inner:-webkit-autofill:hover,
/deep/ .el-input__inner:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px none inset;
  transition: background-color 5000s ease-in-out 0s;
}
</style>
