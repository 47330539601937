export default {
  en: 'English',
  zh: '中文',
  home: {
    RebateAccountNumber: 'Rebate account number',
    totalCommission: 'total commission',
    APPLY_FOR_A_REBATE: 'Apply for a rebate',
    availableBalance: 'Available balance',
    WITHDRAW_REBATE: 'Withdraw',
    TRANSFER_REBATE_INTO_YOUR_TRADING_ACCOUNT: 'Transfer',
    UPDATE: 'Update',
    REBATE: 'Rebate',
    TOTAL_VOLUME: 'Total volume',
    NET_FUNDING: 'Net funding',
    DEPOSITS: 'Deposit',
    WITHDRAW: 'Withdraw',
    OPEND_ACCOUNT: 'Opened account',
    PERFORMANCE: 'Performance',
    ACCOUNT_MANAGER: 'Customer Service',
    MOST_TRADED_INSTRUMENTS: 'Most traded instruments',
    TOP_PERFORMING_ACCOUNTS_FOR_THIS_MONTH: 'Top performing accounts for this month',
    RECENTLY_OPENED_ACCOUNTS: 'Recently opened accounts',
    Account: 'Account',
    Name: 'Name',
    Volume: 'Volume',
    Rebate: 'Rebate',
    Balance: 'Balance',
    ContactClient: 'Contact client',
    applyRebateSuccessTip:
      'Congratulations, we have received your rebate request. | {amount} will be transferred to the rebate Account {account} shortly. | Once processed, you will be able to withdraw or transfer your funds.',
    blacklist:
      'This account is unable to make a rebate at the moment, please contact your account manager.',
  },
  liveChat: { header: 'Live Chat' },
  ibAccounts: { ibAccount: 'IB account', subIbs: 'Sub IB account' },
  rate: {
    rate: 'Exchanges Rates({oldCurrency}/{newCurrency}): ',
    result: {
      rateError: 'Fetch {oldCurrency} to {newCurrency} rate failed.',
      channelError: 'Fetch payment channel failed.',
    },
  },
  withdraw: {
    guide_title: 'Refer to this guide.',
    disclaimer_title: 'Refer to the Disclaimer.',
    banxa_service: 'Sell Cryptocurrencies to Fiat Currencies using the Banxa service.',
    disclaimer:
      "<li>Disclaimer:</li>\n      <li>Please be advised that the exchange rate for selling cryptocurrencies to fiat currencies is determined solely by Banxa, a third-party payment processor. Alphatick brand has no influence or control over the exchange rate offered by Banxa. Therefore, any fluctuations or changes in the exchange rate are beyond our control and are solely attributable to Banxa's policies and market conditions.</li>\n      <li>We recommend that you review the exchange rate and any associated fees or charges offered by Banxa before proceeding with your transaction. Additionally, please note that Alphatick is not responsible for any financial losses or discrepancies resulting from the use of Banxa's services or any other third-party payment processor.</li>\n      <li>Thank you for your understanding and cooperation.</li>\n     ",
    default: {
      verificationCode:
        'Verification code has been sent to {email}. Please contact {supportEmail} if you need further assistance.',
      selectCC: 'Select Credit Card',
      selectBA: 'Select Bank Account',
      anotherCC: 'Enter credit card manually',
      anotherBA: 'Add bank account',
      remember: 'Remember my account',
      failed: 'Withdraw failed. Please try again later',
      tokenExpired: 'Token is expired',
    },
    alert: {
      title: 'Before submitting your withdrawal request, please be aware of the followings:',
      desc1:
        'Due to AML laws, the method you use to withdraw must be the same as the method used for your deposit. If the withdrawal amount is less than {units} units of your account base currency, you will be charged a {charge} unit for handling fee by the bank.',
    },
    cryptoAlert:
      'Please note that we are unable to deposit or withdraw via BUSDT, please ensure the address and Cryptocurrency match the chain and currency we accept or you may loss the fund.',
    usdtNote:
      'The current gas fee of ERC20 is significantly higher than usual and as a result there may be a lengthy delay in the processing of your transaction. Alternatively, you are able to withdraw funds via TRC20 by providing the coresponding wallet address, or you can elect to pay the gas fee to speed up your ERC20 withdrawal.',
    withdrawlFeeAlert:
      'If the withdrawal amount is less than {units} units of your account base currency, you will be charged a {charge} unit for handling fee by the bank.',
    REBATE_WITHDRAW_REQUEST: 'Rebate withdraw request',
    PleaseSelectDesiredWithdrawMethod: 'Please select desired withdraw method',
    BankName: 'Bank name',
    bankCode: 'Bank Code',
    bankBranchCode: 'Branch Code',
    BankAccountsName: 'Bank accounts name',
    FasaPayAccountName: 'FasaPay account name',
    AustralianBankName: 'Australian bank name',
    BankAccountNumber: 'Bank account number',
    bankAccountCurrency: 'Bank Account Currency',
    FasaPayAccountNumber: 'FasaPay account number',
    BankAccountBeneficiary: 'Bank account beneficiary',
    BSB: 'BSB',
    skrillEmail: 'Skrill email',
    NetellerEmail: 'Neteller email',
    BitcoinEmail: 'Bitcoin wallet address',
    USDTEmail: 'Tether wallet address',
    perfectMoneyEmail: 'Perfect Money email',
    Chain: 'Chain',
    BankBranch: 'Bank branch',
    Province: 'Province',
    City: 'City',
    BankAddress: 'Bank address',
    AccountNumberIBAN: 'Account number/IBAN',
    BankBeneficiaryName: 'Bank beneficiary name',
    BankAccountBeneficiaryName: 'Bank account beneficiary name',
    AccountHoldersAddress: "Account holder's address",
    SelectWithdrawCard: 'Select your withdraw card',
    Swift: 'SWIFT',
    SwiftAud: 'SWIFT(Non AUD accounts)',
    ABA_SortCodeABA: 'ABA/Sort code',
    ifscCode: 'IFSC code',
    name: 'Name',
    phone: 'Phone',
    address: 'Address',
    email: 'Email',
    vpa: 'VPA',
    ImportantNotes: 'Important notes',
    YourPaymentGreaterThanAvailableBalance: 'Your payment is greater than the available balance',
    CannotUseMoreThanTwoDecimalValues:
      'You cannot use Numbers and negative Numbers with more than two decimal places',
    YourPaymentIsBeingProcessed: 'Your withdraw application has been submitted',
    Itcannotbe0: "It can't be 0",
    noCardWarn:
      'You don’t have any available card to make withdrawal, please link your card in Withdraw Details first',
    amtLarger: 'Amount cannot be null and must be greater than or equal to ${minLimit}',
    blackList:
      'Unfortunately you are currently unable to submit a withdrawal request. Please contact {supportEmail} for more information',
    blackListTransfer:
      'Unfortunately you are currently unable to submit a transfer request. Please contact {supportEmail} for more information',
    NetellerChargeAlert: 'Neteller charges 2% transaction fee for every withdrawal.',
    FasaPayChargeAlert: 'FasaPay charges 0.5% transaction fee for every withdrawal.',
    SkrillChargeAlert: 'Skrill charges 1% transaction fee for every withdrawal.',
    status: {
      withdrawalSubmitted: 'Submitted',
      withdrawalCanceled: 'Cancelled',
      withdrawalAuditing: 'Processing',
      withdrawalRejected: 'Rejected',
      withdrawalAccepted: 'Processing',
      withdrawalPayFailed: 'Failed',
      withdrawalSuccess: 'Processed',
      withdrawalFailed: 'Failed',
      withdrawalPending: 'Pending',
      withdrawalPartialFailed: 'Failed',
      transferSubmitted: 'Processing',
      transferSuccess: 'Processed',
      transferRejected: 'Rejected',
      transferFailed: 'Failed',
      transferProcessing: 'Processing',
      withdrawalReversedfailed: 'Reversed Failed',
    },
    formErrorMsg: {
      verCodeReq: 'Verification code is required',
      bankName: 'Bank name is required',
      bankCodeReq: 'Bank Code is required',
      bankBranchCodeReq: 'Branch code is required',
      bsb: 'BSB is required',
      beneName: 'Bank beneficiary name is required',
      accNum: 'Bank account number is required',
      swift: 'Swift is required',
      bankAddress: 'Bank address is required',
      holderAddress: 'Holder address is required',
      amt: 'Amount is required',
      firstSixDigits: 'Please enter first 6 digits',
      lastFourDigits: 'Please enter last 4 digits',
      branchName: 'Branch name is required',
      email: 'Email is required',
      accName: 'Account name is required',
      province: 'Bank province is required',
      city: 'Bank city is required',
      bankCard: 'Please select a card',
      emailFormat: 'Please input correct email address',
      bitcoin: 'Bitcoin wallet address is required',
      bitcoinFormat: 'Incorrect Bitcoin wallet address format',
      bitcoinAddressLength: 'Bitcoin wallet address must be between 26 and 42 characters',
      usdt: 'USDT wallet address is required',
      dynamicReq: '{fieldname} is required',
      chain: 'Chain is required',
      walletFormatIncorrect: 'Incorrect {type} wallet address format',
      beneficiaryNameReq: 'Beneficiary Name is required',
    },
    transfer: {
      info: '{platform} will reimburse the bank fee charged for <u>ONE</u> withdrawal per month. Additional withdrawals within the month will incur a bank fee of 20 units of your withdrawal currency.',
      label: {
        upload: 'Please upload a copy of your bank statement (current to the last 3 months)',
      },
      formValidation: { upload: 'Please upload a bank statement' },
    },
    beneficiaryName: 'Beneficiary Name',
  },
  rebate: { status: { processed: 'Processed', processing: 'Processing', rejected: 'Rejected' } },
  transfer: {
    REBATE_TRANSFER_REQUEST: 'Rebate transfer request',
    transferAmountIsGreaterThanAvailableBalance:
      'Your transfer amount is greater than the available balance',
    yourRebateTransferHasBeenProcessed: 'Your rebate transfer has been submitted',
    PleaseEnterTransferInformation: 'Please enter accurate transfer information',
    tradingAccount: 'Trading account',
    rebateAccount: 'Rebate account',
    account: 'Account',
  },
  fileUploader: {
    supportedFormat: 'Supported file types: png, jpg, jpeg, bmp, pdf, doc, docx',
    supportedFormatCustom: 'Supported file types: {files}',
    maxSize: 'Maximum upload file size: {maxSize}MB',
    maxFileNum: 'Maximum file number: {maxFileNum}',
    failedFileExtension: 'Please upload the correct file type',
  },
  common: {
    liveChat: { desc: '<li><p>Need help?</p><span>Live Chat</span></li>' },
    lang: {
      english: 'English',
      chinese: 'Chinese',
      french: 'French',
      thai: 'Thai',
      german: 'German',
      spanish: 'Spanish',
      malay: 'Malay',
      viet: 'Vietnamese',
      indo: 'Indonesian',
      arabic: 'Arabic',
      italiano: 'Italiano',
    },
    button: {
      confirm: 'Confirm',
      cancel: 'Cancel',
      selectFile: 'Select file',
      download: 'Downloads',
      sendCode: 'Send Code',
      resendCode: 'Resend Code',
    },
    withdrawChannel: {
      banktransfer: 'Bank transfer',
      aus: 'Bank Transfer - Australia',
      int: 'Bank Transfer - International',
      chn: 'Bank Transfer - China',
      skrill: 'Skrill',
      skrill_neteller: 'Skrill / Neteller',
      neteller: 'Neteller',
      unionpay: 'UnionPay',
      fasapay: 'FasaPay',
      thailandinstantbankwiretransfer: 'Thailand instant bank wire transfer',
      malaysiainstantbankwiretransfer: 'Malaysia instant bank transfer',
      indonesiainstantbankwiretransfer: 'Indonesia Instant Bank Transfer',
      philippinesinstantbankwiretransfer: 'Philippine Instant Bank Transfer',
      banktransferbpaypolipay: 'Bank transfer/BPay/PoliPay',
      banktransferbpaypolipayaustralia: 'Bank transfer/BPay/PoliPay (Australia)',
      banktransferbpaypolipayinternational: 'Bank transfer/BPay/PoliPay (International)',
      cryptocurrencybitcoin: 'Cryptocurrency-Bitcoin',
      cryptocurrencyusdt: 'Cryptocurrency-USDT',
      vietnaminstantbankwiretransfer: 'Vietnamese instant bank wire transfer',
      laosinstantbankwiretransfer: 'Laos instant bank wire transfer',
      indiainstantbanktransfer: 'India Instant Bank Wire Transfer',
      transfer: 'Transfer to accounts',
      dasshpeupi: 'UPI',
      perfectmoney: 'Perfect Money',
      brazilbanktransfer: 'Brazil Instant Bank Transfer',
      mexicobanktransfer: 'Mexico Instant Bank Transfer',
      internetbankingmexico: 'Internet Banking (Mexico)',
      internetbankingbrazil: 'Internet Banking (Brazil)',
      hongkongbanktransfer: 'Hong Kong Local Bank Transfer',
    },
    country: { international: 'International', australia: 'Australia' },
    field: {
      country_region: 'Country/Region',
      yes: 'Yes',
      no: 'No',
      enterVerificationCode: 'Enter Verification Code',
    },
    keys: {
      SUBMIT: 'Submit',
      FROM: 'From',
      UPDATE: 'Update',
      TO: 'To',
      DATE: 'Date',
      pleaseSelect: 'Please select',
      amount: 'Amount',
      rebateAccount: 'Rebate account',
      comingSoon: 'coming soon',
      tradingHistory: 'Trading history',
      noData: 'No data',
      NAME: 'Name',
      EMAIL: 'Email',
      PHONE: 'Phone Number',
      ACCTYPE: 'Account type',
      PLATFORM: 'Platform',
      BASECURRENCY: 'Base currency',
      DATERANGE: 'Date range',
      STATUS: 'Status',
      DEST: 'Destination',
      ACCNUM: 'Account number',
      BALANCE: 'Balance',
      NONE: 'None',
      ALL: 'All',
      PROFIT: 'Profit',
      MARGINLEVEL: 'Margin Level',
      ACCOUNTEQUITY: 'Account Equity',
      CREDIT: 'Credit',
      createTime: 'Submission Time',
      lastUpdateTime: 'Processed Time',
    },
    dateRange: {
      today: 'Today',
      yesterday: 'Yesterday',
      lastSevenDays: 'Last 7 days',
      lastThirtyDays: 'Last 30 days',
      weekToDate: 'Week to date',
      monthToDate: 'Month to date',
      quarterToDate: 'Quarter to date',
      previousWeek: 'Previous week',
      previousMonth: 'Previous month',
      previousQuarter: 'Previous quarter',
    },
    products: { forex: 'Forex', commodities: 'Commodities', indices: 'Indices', crypto: 'Crypto' },
  },
  report: {
    rebate: {
      column: {
        name: 'Name',
        accountNumber: 'Account number',
        accountType: 'Account type',
        volume: 'Volume',
        rebate: 'Rebate',
        totalRebate: 'Total rebate',
        date: 'Date',
        instrument: 'Instrument',
        notionalvalue: 'Notional Value',
      },
      dropdown: { allInstruments: 'All instruments' },
      others: {
        rebate: 'Rebate',
        instrumentsTraded: 'Instruments traded',
        lots: 'Lots',
        totalRebate: 'Total rebate',
      },
    },
    nodataMessage: 'There is no data filtered. Kindly filter the search criteria before download.',
    timeLimitMessage:
      'maximum query range for download is 90 days,please update the new date range',
  },
  ibReport: {
    netFunding: 'Net funding',
    deposits: 'Deposits',
    withdraw: 'Withdraw',
    openedAccs: 'Opened accounts',
    funding: 'Funding',
  },
  lead: { header: 'Leads', ibAccount: 'IB account', demoAccounts: 'Demo accounts' },
  pendingAccounts: {
    NOTES: 'Notes',
    errorDoc:
      'The following applicants have filled in the online application form but are yet to supply proof of identification (ID) in order to finalise their account.',
    acceptedDocType: 'Accepted forms of ID include:',
    primaryDoc: 'Primary ID',
    idList: [
      'A copy of your valid international passport (signature page)',
      'A copy of your government issued national identity card',
      'A copy of your valid driver’s licence',
    ],
    address: 'Secondary ID',
    addressList: [
      'A copy of a recent public utility bill. E.g. Gas or electricity that contains your name and residential address (no older than 6 months)',
      'A copy of your credit card statement or bank statement (no online print-offs)',
    ],
    tip: 'Please encourage applicants to provide a copy of ONE of the accepted primary and secondary ID documents to {mailTo}',
  },
  rebatePaymentHistory: {
    rebateHistory: 'Rebates',
    withdrawHistory: 'Withdrawals',
    transferHistory: 'Transfers',
    cancelWithdraw: 'Would you like to Cancel the Withdrawal Request?',
    cancelError:
      'Your withdrawal is being processed at the moment. Therefore, we cannot accept your cancellation request. For more information, you may reach us using the live chat feature.',
  },
  transferHistory: {
    payStatus: { processing: 'Processing', paid: 'Paid', unpaid: 'Unpaid', rejected: 'Rejected' },
  },
  referralLinks: {
    ibAcc: 'IB account',
    campLang: 'Campaign language',
    cpy: 'Copy',
    alreadyCPY: 'Copied!',
    liveAcc: 'Live account',
    demoAcc: 'Demo account',
    homePage: 'Home page',
  },
  settings: {
    header: 'Customer information',
    name: 'Customer name',
    emailAdd: 'Email address',
    phone: 'Phone number',
    address: 'Address',
  },
  unfundedAcc: {
    neverFundedAccs: 'Never funded accounts',
    prevFundedZero: 'Previously funded (Zero balance)',
  },
  header: { bckToCP: 'Back to Client Portal', logout: 'Log out' },
  multiib: { level: 'Level {level}', noib: 'There are no sub-IBs under this account.' },
  menu: {
    dashboard: 'Dashboard',
    ibReport: 'IB report',
    rebateReport: 'Rebate report',
    ibAccs: 'IB accounts',
    iblevel: 'Multi-level IB',
    leads: 'Leads',
    pendingAccs: 'Pending accounts',
    unfundedAccs: 'Unfunded accounts',
    payHistory: 'Transaction history',
    ibProfile: 'IB profile',
    refLinks: 'Referral links',
    contactUs: 'Contact us',
    IB_PORTAL: 'IB Portal',
    FEATURES: 'Features',
  },
  subIb: {
    title: 'Shared IB account',
    account: 'Account',
    accountNote: 'Maximum 10 Shared IB accounts',
    subCreateAcc: 'Add',
    subCreateIbAcc: 'Add new Shared IB account',
    editAccount: 'Edit Shared IB account',
    copied: 'Shared IB account copied',
    no: 'No.',
    permission: 'Permission',
    remark: 'Remark',
    status: 'Status',
    confirm: 'Confirm',
    cancel: 'Cancel',
    noPermission: 'No permission for this account',
    setting: 'Setting',
    viewMore: 'View More',
    editPassword: 'Reset password',
    resetPassword: 'Please enter new password',
    delete: 'Delete',
    emailAddress: 'Email Address',
    emailFormat: 'Please enter valid email address',
    permissionTooltip:
      'You can allow the following permissions for shared accounts or not:<li>• Accounts data: View account data pages (IB Accounts, Recently Opened Accounts). The data will be hidden if permission is not allowed.</li><li>• IB report data: View IB data pages (Dashboard, Multi-Level IB - Account). The data will be hidden if permission is not allowed.</li><li>• Funds permission: Operate IB funds (Apply For Rebate, Transfer Rebate, Withdraw Rebate), View rebate (Rebate report, Balance, and Funds records). Operations cannot be performed if permission is not allowed.</li>',
    refresh: 'Refresh',
    historyEnd: 'All history has been shown',
    refreshTips: 'History updated',
    password: 'Password',
    isActive: 'Enabled',
    updatedTime: 'Last updated',
    accWarning: '10 accounts exist, unable to add.',
    isInactive: 'Disabled',
    passwordTooltip:
      '<li>• 8-16 characters</li><li>• Mix of uppercase and lowercase letters (A-Z, a-z), numbers (0-9) and special characters (e.g.: !@#S%^&)</li>',
    resetPasswordTooltip: 'The old password will be invalid and reset to the new password',
    all: 'All',
    chooseAccount: 'Choose Account',
    permissionField: 'Shared IB Account Permission',
    historyTitle: 'Shared account history',
    device: 'Device',
    emailExisting: 'Email exist, please try another one',
    remarkPlaceholder: 'You may record account purpose or account holder',
    passwordRequired: 'Password is required',
    deleteDesc:
      'The Shared IB account will be unable to log in after deletion, do you want to delete it?',
    updateWithdrawalDesc:
      'After fund permission is allowed, the Shared IB account can transfer and withdraw the balance of your main IB account. Do you want to allow this?',
    loginAction: 'Log in',
    applyRebateAction: 'Apply Rebate',
    deleteSuccess: 'Shared IB account has been deleted.',
    enabledSuccess: 'Shared IB account has been enabled.',
    disabledSuccess: 'Shared IB account has been disabled.',
    addSuccess: 'Shared account created',
    modifiedSuccess: 'Modified successfully',
    withdrawRebateAction: 'Withdraw Rebate: From {from} to {to}, amount {amount}',
    passwordSuccess: 'Password reset successful',
    transferRebateAction: 'Transfer Rebate: From {from} to {to}, amount {amount}',
    newPwAccFormat:
      'Your password combination must be between 8-16 characters, and should contains at least an uppercase (A-Z), a lowercase (a-z), a numbers (0-9), and a special characters (!@#$%^&*). If your new password unable update, please reconfirm the combination has meets the requirement.',
    clientData: 'Accounts data',
    ibData: 'IB report data',
    fundsPermission: 'Funds permission',
  },
  footer: {
    riskWarn:
      'RISK WARNING: Trading leveraged derivative products such as Foreign Exchange (Forex), Contracts for Difference (CFDs), or other financial derivative products carry a high level of risk to your capital. All these products, which are leveraged derivative products, may not be appropriate for all investors. The effect of leverage is that both gains and losses are magnified. The prices of leveraged derivative products may change to your disadvantage very quickly, you can lose more than your invested capital, and you may be required to make further payments. Before deciding to invest in any financial product, you should carefully consider your investment objectives, trading knowledge and experience, and affordability. You should only trade in Forex and CFDs if you have sufficient knowledge and experience of the risky nature of the products, the risks involved in trading such products, and if you are dealing with money that you can afford to lose. You should seek independent professional financial advice if you are in any doubt.',
  },
  responseMsg: {
    410: 'Parameter validation failed',
    411: 'Incorrect password',
    420: 'User does not exist',
    421: 'Password reset denied',
    490: 'Please do not submit duplicate card information',
    500: 'An error has occured.',
    501: 'Application failed. Please try again later.',
    505: 'File upload failed',
    520: 'Login failed. Please try again.',
    521: 'A user is already logged in on this device!',
    522: 'Please log in again',
    523: 'Please log in again',
    524: 'Unfortunately your email address was not recognized',
    525: 'Incorrect username or password',
    527: 'User is not an IB',
    528: 'User does not exist',
    529: 'Incorrect username or password',
    540: "Your trading account couldn't be found",
    541: 'Cannot find rebate account',
    542: 'You are not allowed to open additional account',
    544: 'You are not allowed to apply for an additional account until your Proof of ID verified',
    550: 'Incorrect account number',
    551: 'Your balance is insufficient',
    553: 'You cannot withdraw/transfer due to the credit in your account. Please contact {email} for further help',
    554: 'Requested amount is zero or null',
    562: 'We were unable to process your payment, please try again. If the problem persists, please contact us via live chat, or email {email}',
    564: 'Country does not match the payment channel',
    565: 'Incorrect bank code',
    566: 'Payment method not found',
    581: 'You have successfully opted in to the promotion. Please note that you must make a deposit in order to withdraw funds.',
    584: "You don't have any applicable rebates",
    590: 'Cannot find transfer-out account',
    591: 'Cannot find transfer-in account',
    593: 'We are unable to process your funds transfer from the account selected due to your account containing credit.',
    594: 'Transfer of funds from this account is not allowed',
    1101: '10 accounts exist, unable to add.',
    1102: 'Unable to enable, the account has been created as a live or demo account.',
    session_timeout: 'Session timeout, redirecting to login page.',
  },
};
