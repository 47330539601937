//需转码urls列表
export const FILTER_URLS = [
    'sub-account',
    'login',
    'ibaccount',
    'unfundedaccount',
    'ibreport/queryIbReportData',
    'tradeaccount/getPendingAccount',
    'menu/leads',
    'rebate-report',
    'withdrawal/creditCard/balances',
    'withdrawal/queryUnionpayCard',
    'payment/info/getPaymentInfoList/',
    'withdrawal/creditCard',
    'report/get-instruments',
    'transfer/sub-ib/info',
    'contact/contactinfo',
    'verificationV2/getData',
    'TradeRebateVolume/queryRebateVolumeList',
    'tradeaccount/getNearestOpenAccount',
    'banner/enabled'
]