export default {
  en: 'Inglês',
  zh: '中文',
  home: {
    RebateAccountNumber: 'Número da conta de comissão',
    totalCommission: 'comissão total',
    APPLY_FOR_A_REBATE: 'SOLICITAR COMISSÃO',
    availableBalance: 'saldo disponível',
    WITHDRAW_REBATE: 'LEVANTAR',
    TRANSFER_REBATE_INTO_YOUR_TRADING_ACCOUNT: 'TRANSFERIR',
    UPDATE: 'ATUALIZAR',
    REBATE: 'COMISSÃO',
    TOTAL_VOLUME: 'VOLUME TOTAL',
    NET_FUNDING: 'FINANCIAMENTO LÍQUIDO',
    DEPOSITS: 'DEPÓSITOS',
    WITHDRAW: 'LEVANTAR',
    OPEND_ACCOUNT: 'CONTA ABERTA',
    PERFORMANCE: 'DESEMPENHO',
    ACCOUNT_MANAGER: 'GERENTE DE CONTAS',
    MOST_TRADED_INSTRUMENTS: 'INSTRUMENTOS MAIS NEGOCIADOS',
    TOP_PERFORMING_ACCOUNTS_FOR_THIS_MONTH: 'CONTAS COM MELHOR DESEMPENHO PARA ESTE MÊS',
    RECENTLY_OPENED_ACCOUNTS: 'CONTAS ABERTAS RECENTEMENTE',
    Account: 'Conta',
    Name: 'Nome',
    Volume: 'Volume',
    Rebate: 'COMISSÃO',
    Balance: 'Saldo',
    ContactClient: 'Cliente de contato',
    applyRebateSuccessTip:
      'Parabéns, recebemos sua solicitação de reembolso. | {montante} será transferido para a conta de reembolso {conta} dentro de 1 dia útil. | Você pode sacar ou transferir fundos assim que seu reembolso for processado.',
    blacklist:
      'Esta conta não pode obter uma compensação no momento, por favor, contacte o seu gestor de conta.',
  },
  liveChat: { header: 'Bate-papo ao vivo' },
  ibAccounts: { ibAccount: 'Conta IB', subIbs: 'Conta Sub IB' },
  withdraw: {
    default: {
      selectCC: 'Selecione o cartão de crédito',
      selectBA: 'Selecione a Conta Bancária',
      anotherCC: 'Digite o cartão de crédito manualmente',
      anotherBA: 'Adicionar conta bancária',
      remember: 'Lembrar minha conta',
      failed: 'O levantamento falhou. Por favor, tente novamente mais tarde',
      tokenExpired: 'O token expirou',
    },
    transfer: {
      info: 'A {platform} irá reembolsar a taxa bancária cobrada por <u>UM</u> levantamento por mês. Os levantamentos adicionais dentro do mês incorrem numa taxa bancária de 20 unidades da sua moeda de levantamento.',
      label: {
        upload:
          'Por favor, faça o carregamento de uma cópia do seu extrato bancário (dos últimos 3 meses correntes)',
      },
      formValidation: { upload: 'Por favor, faça o envio de um extrato bancário' },
    },
    alert: {
      title: 'Antes de submeter o seu pedido de levantamento, por favor note o seguinte:',
      desc1:
        'Devido às leis AML, o método que você usa para retirar deve ser o mesmo que o método usado para seu depósito. Se o valor do saque for inferior a {units} unidades da moeda base de sua conta, será cobrada uma taxa de {charge} unidades pelo banco para manuseio.',
    },
    cryptoAlert:
      'Por favor, tenha em atenção que não podemos depositar ou levantar via BUSDT, por favor, certifique-se de que o endereço e a moeda Criptomoeda correspondem à corrente e moeda que aceitamos ou poderá perder o fundo.',
    REBATE_WITHDRAW_REQUEST: 'Pedido de saque de comissão.',
    PleaseSelectDesiredWithdrawMethod: 'Selecione o método de levantamento desejado',
    BankName: 'Nome do banco',
    bankCode: 'Código do Banco',
    bankBranchCode: 'Código de sucursal',
    BankAccountsName: 'Nome das contas bancárias',
    FasaPayAccountName: 'Nome da conta FasaPay',
    AustralianBankName: 'Nome do banco australiano',
    BankAccountNumber: 'Número da conta de banco',
    bankAccountCurrency: 'Moeda da Conta Bancária',
    FasaPayAccountNumber: 'Número da conta FasaPay',
    BankAccountBeneficiary: 'Beneficiário da conta bancária',
    BSB: 'BSB',
    skrillEmail: 'Email Skrill',
    NetellerEmail: 'Neteller Email',
    BitcoinEmail: 'Endereço da Carteira Bitcoin',
    USDTEmail: 'Endereço da carteira Tether',
    perfectMoneyEmail: 'Email Perfect Money',
    BankBranch: 'Agência Bancária',
    Province: 'Província',
    City: 'Cidade',
    BankAddress: 'Endereço do banco',
    AccountNumberIBAN: 'Número da conta / IBAN',
    BankBeneficiaryName: 'Nome do beneficiário do banco',
    BankAccountBeneficiaryName: 'Nome do beneficiário da conta bancária',
    AccountHoldersAddress: 'Endereço do titular da conta',
    SelectWithdrawCard: 'Selecione o seu cartão de retirada',
    Swift: 'SWIFT',
    SwiftAud: 'Swift (contas não AUD)',
    ABA_SortCodeABA: 'ABA / Código de classificação',
    name: 'Nome',
    phone: 'Número de Telefone',
    address: 'Endereço',
    email: 'E-mail',
    ImportantNotes: 'Notas importantes',
    YourPaymentGreaterThanAvailableBalance: 'Seu pagamento é maior que o saldo disponível',
    CannotUseMoreThanTwoDecimalValues:
      'Você não pode usar números e números negativos com mais de duas casas decimais',
    YourPaymentIsBeingProcessed: 'Seu pedido de levantamento foi enviado',
    Itcannotbe0: 'Não pode ser 0',
    noCardWarn:
      'Você não tem nenhum cartão disponível para fazer levantamentos, primeiro vincule seu cartão aos Detalhes de saque',
    amtLarger: 'O valor não pode ser nulo e deve ser maior ou igual a $ {minLimit}',
    blackList:
      'Infelizmente, não pode atualmente submeter um pedido de levantamento. Por favor contacte {supportEmail} para mais informações',
    NetellerChargeAlert: 'O Neteller cobra uma taxa de transação de 2% para cada retirada.',
    FasaPayChargeAlert: 'O FasaPay cobra uma taxa de transação de 0,5% para cada retirada.',
    SkrillChargeAlert: 'Skrill cobra taxa de transação de 1% para cada retirada.',
    status: {
      withdrawalSubmitted: 'Submetido',
      withdrawalCanceled: 'Cancelado',
      withdrawalAuditing: 'Em processamento',
      withdrawalRejected: 'Rejeitado',
      withdrawalAccepted: 'Em processamento',
      withdrawalPayFailed: 'Falhou',
      withdrawalSuccess: 'Processado',
      withdrawalFailed: 'Falhou',
      withdrawalPending: 'Pendente',
      withdrawalPartialFailed: 'Falhou',
      transferSubmitted: 'Em processamento',
      transferSuccess: 'Processado',
      transferRejected: 'Rejeitado',
      transferFailed: 'Falhou',
      transferProcessing: 'Em processamento',
      withdrawalReversedfailed: 'Falha Revertida',
    },
    formErrorMsg: {
      bankName: 'Nome do banco é obrigatório',
      bankCodeReq: 'O Código bancário é obrigatório',
      bankBranchCodeReq: 'O Código da sucursal é obrigatório',
      bsb: 'BSB é obrigatório',
      beneName: 'O nome do beneficiário do banco é obrigatório',
      accNum: 'O número da conta bancária é obrigatório',
      swift: 'Swift é necessário',
      bankAddress: 'O endereço do banco é obrigatório',
      holderAddress: 'O endereço do titular é obrigatório',
      amt: 'Quantidade é necessária',
      firstSixDigits: 'Digite os 6 primeiros dígitos',
      lastFourDigits: 'Insira os 4 últimos dígitos',
      branchName: 'Nome da filial é obrigatório',
      email: 'E-mail é obrigatório',
      accName: 'O nome da conta é obrigatório',
      province: 'A província do banco é necessária',
      city: 'Cidade do banco é obrigatória',
      bankCard: 'Selecione um cartão',
      emailFormat: 'Please input correct email address',
      bitcoin: 'O endereço da Carteira Bitcoin é obrigatório',
      bitcoinAddressLength: 'O endereço da carteira de Bitcoin deve ter entre 26 e 42 caracteres',
      usdt: 'O endereço da Carteira USDT é obrigatório',
      dynamicReq: '{fieldname} é obrigatório',
      beneficiaryNameReq: 'O nome do beneficiário do banco é obrigatório',
    },
    beneficiaryName: 'Nome do beneficiário',
  },
  rebate: {
    status: { processed: 'Processado', processing: 'Em processamento', rejected: 'Rejeitado' },
  },
  transfer: {
    REBATE_TRANSFER_REQUEST: 'PEDIDO DE TRANSFERÊNCIA DE COMISSÃO',
    transferAmountIsGreaterThanAvailableBalance:
      'O valor da sua transferência é maior do que o saldo disponível',
    yourRebateTransferHasBeenProcessed: 'sua transferência de reembolso foi enviada',
    PleaseEnterTransferInformation: 'Insira informações de transferência precisas',
    tradingAccount: 'Conta de negociação',
    rebateAccount: 'Conta de comissão',
    account: 'Conta',
  },
  fileUploader: {
    supportedFormat: 'Tipos de ficheiros suportados: png, jpg, jpeg, bmp, pdf, doc, docx',
    supportedFormatCustom: 'Tipos de ficheiros suportados: {files}',
    maxSize: 'Tamanho máximo do ficheiro para carregamento: {maxSize}MB',
    maxFileNum: 'Número máximo de ficheiros: {maxFileNum}',
    failedFileExtension: 'Carregue o tipo de arquivo correto',
  },
  common: {
    liveChat: { desc: '<li><p>Precisa de ajuda?</p><span>Chat ao Vivo</span></li>' },
    lang: {
      english: 'Inglês',
      chinese: 'chinês',
      french: 'francês',
      thai: 'tailandês',
      german: 'alemão',
      spanish: 'espanhol',
      malay: 'malaio',
      viet: 'vietnamita',
      indo: 'indonésio',
      arabic: 'árabe',
    },
    button: { confirm: 'CONFIRME', cancel: 'CANCELAR', selectFile: 'Selecionar Ficheiro' },
    withdrawChannel: {
      hongkongbanktransfer: 'Transferência Bancária Local de Hong Kong',
      banktransfer: 'Transferência bancária',
      aus: 'Transferência Bancária - Austrália',
      int: 'Transferência Bancária - Internacional',
      chn: 'Transferência Bancária - China',
      skrill: 'Skrill',
      skrill_neteller: 'Skrill / Neteller',
      neteller: 'Neteller',
      unionpay: 'UnionPay',
      fasapay: 'FasaPay',
      thailandinstantbankwiretransfer: 'Transferência Bancária Instantânea da Tailândia',
      malaysiainstantbankwiretransfer: 'Transferência Bancária Instantânea da Malásia ',
      indonesiainstantbankwiretransfer: 'Transferência Bancária Instantânea Indonésia',
      philippinesinstantbankwiretransfer: 'Transferência Bancária Instantânea da Philippine',
      banktransferbpaypolipay: 'Transferência Bancária / BPay / PoliPay',
      banktransferbpaypolipayaustralia: 'Transferência Bancária / BPay / PoliPay (Austrália)',
      banktransferbpaypolipayinternational:
        'Transferência Bancária / BPay / PoliPay (Internacional)',
      cryptocurrencybitcoin: 'Cryptocurrency-Bitcoin',
      cryptocurrencyusdt: 'Cryptocurrency-USDT',
      vietnaminstantbankwiretransfer: 'Transferência Bancária Instantânea da vietnamita',
      laosinstantbankwiretransfer: 'Transferência Bancária Instantânea de Laos',
      indiainstantbanktransfer: 'Transferência Bancária Instantânea India',
      brazilbanktransfer: 'Transferência Bancária Instantânea Brasil',
      mexicobanktransfer: 'Transferência Bancária Instantânea México',
      internetbankingmexico: 'Serviço Bancário Online (México)',
      internetbankingbrazil: 'Serviço Bancário Online (Brasil)',
      transfer: 'Transferência para contas de negociação',
    },
    country: { international: 'Internacional', australia: 'Austrália' },
    field: { country_region: 'País / Região', yes: 'Sim', no: 'Não' },
    keys: {
      SUBMIT: 'ENVIAR',
      FROM: 'DE',
      UPDATE: 'ATUALIZAR',
      TO: 'PARA',
      DATE: 'DATA',
      pleaseSelect: 'Por favor selecione',
      amount: 'Montante',
      rebateAccount: 'Conta de comissão',
      comingSoon: 'em breve',
      tradingHistory: 'HISTÓRIA DE NEGOCIAÇÃO',
      noData: 'Sem dados',
      NAME: 'Nome',
      EMAIL: 'O email',
      PHONE: 'Número de Telefone',
      ACCTYPE: 'TIPO DE CONTA',
      PLATFORM: 'PLATAFORMA',
      BASECURRENCY: 'MOEDA BASE',
      DATERANGE: 'INTERVALO DE DATA',
      STATUS: 'STATUS',
      DEST: 'DESTINO',
      ACCNUM: 'NÚMERO DA CONTA',
      BALANCE: 'Saldo',
      NONE: 'Nenhum',
      ALL: 'Todos',
      PROFIT: 'Lucro',
      MARGINLEVEL: 'Nível de Margem (%)',
      ACCOUNTEQUITY: 'Equidade da Conta',
      CREDIT: 'Crédito',
      createTime: 'Hora de submissão',
      lastUpdateTime: 'Tempo processado',
    },
    dateRange: {
      today: 'Hoje',
      yesterday: 'Ontem',
      lastSevenDays: 'Últimos 7 dias',
      lastThirtyDays: 'Últimos 30 dias',
      weekToDate: 'Semana até a data',
      monthToDate: 'Do mês até a data',
      quarterToDate: 'Trimestre até a data',
      previousWeek: 'Semana anterior',
      previousMonth: 'Mês anterior',
      previousQuarter: 'Trimestre Anterior',
    },
    products: { forex: 'FOREX', commodities: 'COMMODITIES', indices: 'ÍNDICES', crypto: 'CRYPTO' },
  },
  report: {
    rebate: {
      column: {
        name: 'Nome',
        accountNumber: 'NÚMERO DA CONTA',
        accountType: 'TIPO DE CONTA',
        volume: 'Volume',
        rebate: 'COMISSÃO',
        totalRebate: 'COMISSÃO TOTAL',
        date: 'DATA',
        instrument: 'INSTRUMENTO',
        notionalvalue: 'VALOR NOCIONAL',
      },
      dropdown: { allInstruments: 'Todos os instrumentos' },
      others: {
        rebate: 'COMISSÃO',
        instrumentsTraded: 'INSTRUMENTOS NEGOCIADOS',
        lots: 'quantidade',
        totalRebate: 'Desconto Total',
      },
    },
  },
  ibReport: {
    netFunding: 'FINANCIAMENTO LÍQUIDO',
    deposits: 'DEPÓSITOS',
    withdraw: 'LEVANTAR',
    openedAccs: 'CONTAS ABERTAS',
    funding: 'FINANCIAMENTO',
  },
  lead: { header: 'Leads', ibAccount: 'Conta IB', demoAccounts: 'CONTAS DEMO' },
  pendingAccounts: {
    NOTES: 'Notas',
    errorDoc:
      'Os seguintes candidatos preencheram o formulário de inscrição online, mas ainda precisam fornecer prova de identificação (ID) para finalizar sua conta.',
    acceptedDocType: 'As formas de identificação aceitas incluem:',
    primaryDoc: 'ID primária',
    idList: [
      'Uma cópia do seu passaporte internacional válido (página de assinatura)',
      'Uma cópia da carteira de identidade nacional emitida pelo governo',
      'Uma cópia de sua carteira de motorista válida',
    ],
    address: 'ID Secundário',
    addressList: [
      'Uma cópia de uma conta de serviço público recente. Por exemplo, gás ou eletricidade que contenha seu nome e endereço residencial (não mais de 6 meses)',
      'Uma cópia do extrato do seu cartão de crédito ou extrato bancário (sem  online)',
    ],
    tip: 'Incentive os candidatos a fornecer uma cópia de UM dos documentos de identidade primários e secundários aceitos para {mailTo}',
  },
  transferHistory: {
    payStatus: {
      processing: 'Em Processamento',
      paid: 'Pago',
      unpaid: 'Por pagar',
      rejected: 'Rejeitado',
    },
  },
  rebatePaymentHistory: {
    transferHistory: 'HISTÓRICO DE TRANSFERÊNCIAS',
    rebateHistory: 'HISTÓRICO DE COMISSÃO',
    withdrawHistory: ' HISTÓRICO DE LEVANTAMENTOS',
  },
  referralLinks: {
    ibAcc: 'Conta IB',
    campLang: 'Idioma da campanha',
    cpy: 'CÓPIA DE',
    alreadyCPY: 'COPIADO!',
    liveAcc: 'Conta Real',
    demoAcc: 'Conta Demo',
    homePage: 'Pagina inicial',
  },
  settings: {
    header: 'Informação do Cliente',
    name: 'Nome do Cliente',
    emailAdd: 'O EMAIL',
    phone: 'Número de Telefone',
    address: 'Endereço',
  },
  unfundedAcc: {
    neverFundedAccs: ' CONTAS NUNCA FINANCIADAS',
    prevFundedZero: 'FINANCIADO ANTERIORMENTE (SALDO ZERO)',
  },
  header: { bckToCP: 'VOLTAR AO PORTAL DO CLIENTE', logout: 'SAIR' },
  multiib: { level: 'NÍVEL {level}', noib: 'Não há sub-IBs sob esta conta.' },
  menu: {
    dashboard: 'PAINEL DE CONTROLE',
    ibReport: 'RELÁTORIO IB ',
    rebateReport: 'RELATÓRIO DE COMISSÃO',
    ibAccs: 'CONTAS IB',
    iblevel: 'MULTI-NÍVEL IB',
    leads: 'Leads',
    pendingAccs: 'CONTAS PENDENTES',
    unfundedAccs: 'CONTAS NÃO FINANCIADAS',
    payHistory: 'HISTÓRICO DE TRANSAÇÕES',
    ibProfile: 'PERFIL IB ',
    refLinks: 'LINKS DE REFERÊNCIA',
    contactUs: 'CONTATE-NOS',
    IB_PORTAL: 'PORTAL IB',
    FEATURES: 'RECURSOS',
  },
  subIb: {
    title: 'Conta IB compartilhada',
    account: 'Conta',
    accountNote: 'Máximo de 10 contas IB compartilhadas',
    subCreateAcc: 'Adicionar',
    subCreateIbAcc: 'Adicionar nova conta IB compartilhada',
    editAccount: 'Editar conta IB compartilhada',
    copied: 'Conta IB compartilhada copiada',
    no: 'Número',
    permission: 'Permissão',
    remark: 'Observação',
    status: 'Status',
    confirm: 'Confirmar',
    cancel: 'Cancelar',
    noPermission: 'Sem permissão para esta conta',
    setting: 'Configuração',
    viewMore: 'Ver mais',
    editPassword: 'Redefinir senha',
    resetPassword: 'Digite a nova senha',
    delete: 'Excluir',
    emailAddress: 'Endereço de e-mail',
    emailFormat: 'Por favor, insira um endereço de e-mail válido',
    permissionTooltip:
      'Você pode permitir ou não as seguintes permissões para contas compartilhadas:<li>• Dados da conta: Visualizar páginas de dados da conta (Contas IB, Contas abertas recentemente). Os dados serão ocultados se a permissão não for permitida.</li><li>• Dados do relatório IB: Visualizar páginas de dados do IB (Painel, IB multinível - conta). Os dados serão ocultados se a permissão não for permitida. </li><li>• Permissão de fundos: Operar com fundos IB (Solicitar rebate, Transferir rebate, Retirar rebate), Ver reembolso (relatório de descontos, registros de saldo e fundos). As operações não podem ser realizadas se a permissão não for permitida. </li>',
    refresh: 'Atualizar',
    historyEnd: 'Todo o histórico foi mostrado',
    refreshTips: 'Histórico atualizado',
    password: 'Senha',
    isActive: 'Ativado',
    updatedTime: 'Última atualização',
    accWarning: 'Existem 10 contas, não é possível adicionar mais.',
    isInactive: 'Desativado',
    passwordTooltip:
      '<li>• 8 a 16 caracteres</li><li>• Mistura de letras maiúsculas e minúsculas (A-Z, a-z), números (0-9) e caracteres especiais (por exemplo: !@#S%^&)</li>',
    resetPasswordTooltip: 'A senha antiga será invalidada e redefinida com a nova senha',
    all: 'Todos',
    chooseAccount: 'Escolher Conta',
    permissionField: 'Permissão da Conta IB Compartilhada',
    historyTitle: 'Histórico da conta compartilhada',
    device: 'Dispositivo',
    emailExisting: 'O e-mail já existe, por favor, tente outro',
    remarkPlaceholder: 'Você pode registrar o propósito da conta ou o titular da conta',
    passwordRequired: 'A palavra-passe é obrigatória',
    deleteDesc:
      'A conta IB compartilhada não poderá fazer login após a exclusão, você deseja excluí-la?',
    updateWithdrawalDesc:
      'Após a permissão de depósito ser concedida, a conta IB compartilhada pode transferir e retirar o saldo de sua conta IB principal. Você deseja permitir isso?',
    loginAction: 'Login',
    applyRebateAction: 'Aplicar rebate',
    deleteSuccess: 'A conta IB compartilhada foi excluída.',
    enabledSuccess: 'A conta IB compartilhada foi ativada.',
    disabledSuccess: 'A conta IB compartilhada foi desativada.',
    addSuccess: 'Conta compartilhada criada',
    modifiedSuccess: 'Modificado com sucesso',
    withdrawRebateAction: 'Retirar rebate: De {from}  para {to}, valor {amount}',
    passwordSuccess: 'Redefinição de senha bem-sucedida',
    transferRebateAction: 'Transferir rebate: De {from} para {to}, valor {amount}',
    newPwAccFormat:
      'A sua palavra-chave deve ter entre 8-16 caracteres e uma mistura de letras (a-z e A-Z), números (0-9) e caracteres especiais como !@#$%^&*',
    clientData: 'Dados da conta',
    ibData: 'Dados do relatório IB',
    fundsPermission: 'Permissão de fundos',
  },
  footer: {
    riskWarn:
      'Aviso de risco: Negociar Forex e CFDs envolve um risco significativo e pode resultar na perda do seu investimento\n    capital. A comercialização de produtos alavancados pode não ser adequada para todos os investidores. Antes de negociar, por favor, leve em consideração\n    seu nível de experiência, objetivos de investimento e procure aconselhamento financeiro independente, se necessário.\n    Por favor, leia nossos documentos legais em nosso site e certifique-se de compreender totalmente os riscos antes de fazer qualquer\n    decisões de negociação.',
  },
  responseMsg: {
    410: 'A validação dos parâmetros falhou',
    411: 'Palavra-passe incorreta',
    420: 'O utilizador não existe',
    421: 'Redefinição de Palavra-passe negada',
    490: 'Por favor, não submeta informações duplicadas do cartão',
    500: 'Ocorreu um erro.',
    501: 'A Inscrição falhou. Por favor, tente novamente mais tarde.',
    505: 'O carregamento de ficheiros falhou',
    520: 'Falha na autenticação. Por favor, tente novamente.',
    521: 'Um utilizador já está ligado neste dispositivo!',
    522: 'Por favor, inicie a sessão novamente',
    523: 'Por favor, inicie a sessão novamente',
    524: 'Infelizmente o seu endereço de e-mail não foi reconhecido',
    525: 'Nome de utilizador ou palavra-passe incorretos',
    527: 'O utilizador não é um IB',
    528: 'O utilizador não existe',
    529: 'Nome de utilizador ou palavra-passe incorretos',
    540: 'Não foi possível encontrar a sua conta de negociação',
    541: 'Não foi possível encontrar a conta de compensação',
    542: 'Você não tem permissão para abrir uma conta adicional',
    544: 'Não está autorizado a candidatar-se a uma conta adicional até que o seu Comprovativo de Identificação seja verificado',
    550: 'Número de conta incorreto',
    551: 'O seu saldo é insuficiente',
    553: 'Não pode levantar/transferir devido ao crédito na sua conta. Por favor, entre em contacto com {email} para obter mais ajuda',
    554: 'O valor solicitado é zero ou nulo',
    562: 'Não foi possível processar seu pagamento, por favor tente novamente. Se o problema persistir, favor entrar em contato conosco via chat, ou e-mail {email}.',
    564: 'O país não corresponde ao canal de pagamento',
    565: 'Código Bancário incorreto',
    566: 'Método de pagamento não encontrado',
    581: 'Você optou com sucesso pela promoção. Observe que você deve fazer um depósito a fim de retirar fundos.',
    584: 'Não tem compensações aplicáveis',
    590: 'Não foi possível encontrar a conta da transferência realizada',
    591: 'Não foi possível encontrar a conta da transferência recebida',
    593: 'Não podemos processar a sua transferência de fundos da conta selecionada devido à sua conta conter crédito.',
    594: 'A transferência de fundos a partir desta conta não é permitida',
    1101: 'Existem 10 contas, não é possível adicionar mais.',
    1102: 'Não foi possível ativar, a conta foi criada como uma conta real ou demo.',
    session_timeout:
      'A sessão chegou ao tempo limite, você será redirecionando para a página de login.',
  },
};
