import router from '@/router'
import { startLoading, endLoading } from '@/util/loading'
import store from '@/store'
import helper from '@/util/signinHelper'
import { getCookies } from '@/util/cookies'
import { parseSecureURL } from '@/util/secureURLUtils';

export default {
  authorize() {
    router.beforeEach(async (to, from, next) => {
      const loginStatus = store.state.common.loginStatus
      const accessToken = to.query.accessToken;

      if (accessToken) {
        try {
          const decryptToken =  await parseSecureURL(accessToken);
          helper.loginByToken(decryptToken.accessToken, decryptToken.pk);
        } catch (error) {
          console.error('Failed to decrypt accessToken:', error.message);
          helper.signOut('logout')
        }
      } else if (!loginStatus && !accessToken) helper.getCpUrl('logout')
      else if (loginStatus) {
        if (!getCookies('token')) helper.signOut()
        else {
          startLoading('router')
          next()
        }
      }
    })
    router.afterEach((to, from) => {
      endLoading()
    })
  },
}
