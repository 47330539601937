const i18n = require('@/lang/index')
const cima = require('@/regulator/cima.js')
const svg = require('@/regulator/svg.js')

const regulator = {
  cima,
  svg,
}

module.exports = {
  cpUrl: 'https://myaccount.vtmarkets.com.cn',
  info: {
    fullName: 'AlphaTick',
    entityName: 'ALPHATICK LLC',
    getUrl(reg) {
      return reg ? regulator[reg].info.url : ''
    },
    getUrl2(reg) {
      return reg ? regulator[reg].info.url2 : ''
    },
    getUrl3(reg) {
      return reg ? regulator[reg].info.url3 : ''
    },
    getUrl4(reg) {
      return reg ? regulator[reg].info.url4 : ''
    },
    getUrl5(reg) {
      return reg ? regulator[reg].info.url5 : ''
    },
    getMap(reg) {
      return regulator[reg].info.map
    },
    getAddress(reg) {
      return regulator[reg].info.address
    },
    getEmail(reg) {
      return reg ? regulator[reg].info.email : ''
    },
    getName(reg) {
      return reg ? regulator[reg].info.name : ''
    },
    getPhone(reg) {
      return reg ? regulator[reg].info.phone : ''
    },
    getWithdrawalEmail(reg) {
      return reg ? regulator[reg].info.withdrawalEmail : ''
    },
  },
  menu: [
    {
      name: '',
      children: [
        {
          name: 'menu.dashboard',
          path: '/home',
          icon: require('@/assets/uploads/menu/menu_icon-dashboard.png'),
        },
      ],
    },
    {
      name: 'menu.FEATURES',
      children: [
        {
          name: 'menu.ibReport',
          path: '/ibReport',
          icon: require('@/assets/uploads/menu/menu_icon-report.png'),
        },
        {
          name: 'menu.rebateReport',
          path: '/rebateReport',
          icon: require('@/assets/uploads/menu/menu_icon-rebate.png'),
        },
        {
          name: 'menu.iblevel',
          path: '/iblevel',
          icon: require('@/assets/uploads/menu/menu_icon-multi-level.png'),
        },
        {
          name: 'menu.ibAccs',
          path: '/ibAccounts',
          icon: require('@/assets/uploads/menu/menu_icon-ib-accounts.png'),
        },
        {
          name: 'menu.pendingAccs',
          path: '/pendingAccounts',
          icon: require('@/assets/uploads/menu/menu_icon-pending.png'),
        },
        {
          name: 'menu.leads',
          path: '/leads',
          icon: require('@/assets/uploads/menu/menu_icon-leads.png'),
        },
        {
          name: 'menu.unfundedAccs',
          path: '/unfundedAccounts',
          icon: require('@/assets/uploads/menu/menu_icon-unfunded.png'),
        },
        {
          name: 'menu.payHistory',
          path: '/RebatePaymentHistory',
          icon: require('@/assets/uploads/menu/menu_icon-history.png'),
        },
        {
          name: 'menu.ibProfile',
          path: '/settings',
          icon: require('@/assets/uploads/menu/menu_icon-ib-profile.png'),
        },
        {
          name: 'menu.refLinks',
          path: '/ReferralLinks',
          icon: require('@/assets/uploads/menu/menu_icon-referral.png'),
        },
        {
          name: 'menu.contactUs',
          path: '/contactUs',
          icon: require('@/assets/uploads/menu/menu_icon_contact.png'),
        },
      ],
    },
  ],
  accountTypeMaps: {
    0: 'ALL',
    1: 'Standard STP',
    2: 'Alpha ECN',
    3: 'Standard STP & Binary Options',
    4: 'Raw ECN & Binary Options',
    5: 'Binary Options Only',
    6: 'Swap-free Standard STP',
    7: 'Swap-free Alpha ECN',
    8: 'PRO ECN',
    9: 'VIP STP',
    10: 'PAMM',
    101: 'Standard STP', // MT5 Standard STP
    11: 'Net STP',
    12: 'Net ECN',
    13: 'Hedge STP', // Hedge STP
    14: 'Hedge ECN', // Hedge ECN
    15: 'MT5 Swap free STP', // MT5 ISLAMIC STP
    16: 'MT5 Swap free ECN ', // MT5 ISLAMIC ECN
    17: 'MT5 PRO', //MT5 PRO ECN
    18: 'VIP STP',
    28: 'MT5 PAMM Investor'
  },
  country_transferOptions: [
    {
      id: 2,
      value: 'common.country.international',
    },
  ],
  chartColor: ['#0051ff', '#002981', '#99b9ff', '#00f0ff', '#ccfcff'],
  getCancelWithdraw(reg) {
    return reg ? regulator[reg].withdrawSetup.cancelWithdraw : false
  },
}
