import Vue from 'vue'
import Router from 'vue-router'
import i18n from '@/lang'
import identity from './identity'
import store from '@/store/index'
import { defaultVariableMapping } from '@/components/profile/SubProfile.js'
import { Message } from 'element-ui'
Vue.use(Router)

function canParticipate(path) {
  const relatedPath = ['/rebateTransfer', '/rebateWithdraw']
  //https://stackoverflow.com/questions/8217419/how-to-determine-if-javascript-array-contains-an-object-with-an-attribute-that-e
  //https://stackoverflow.com/questions/42603909/accessing-vuex-state-when-defining-vue-router-routes
  return relatedPath.includes(path) && store.state.common.isSubUser
    ? store.state.common.subPermission.includes(defaultVariableMapping['depositWithdrawal'])
    : true
}

const pages = {
  '/home': () => import('@/views/home/index'),
  '/ibreport': () => import('@/views/IbReport'),
  '/rebatereport': () => import('@/views/RebateReport'),
  '/iblevel': () => import('@/views/IbLevel'),
  '/ibaccounts': () => import('@/views/IbAccounts'),
  '/pendingaccounts': () => import('@/views/PendingAccounts'),
  '/leads': () => import('@/views/Leads'),
  '/unfundedaccounts': () => import('@/views/UnfundedAccounts'),
  '/rebateTransfer': () => import('@/views/RebateTransfer'),
  '/rebateWithdraw': () => import('@/views/RebateWithdraw'),
  '/settings': () => import('@/views/Settings'),
  '/contactUs': () => import('@/views/ContactUs'),
  '/rebatePaymentHistory': () => import('@/views/RebatePaymentHistory'),
  '/referralLinks': () => import('@/views/ReferralLinks'),
}

let routes = [
  {
    path: '',
    component: () => import('@/views/Layout'),
    children: [
      {
        name: '/',
        path: '/',
        component: () => import('@/views/home/index'),
      },
      ...Object.keys(pages).map(b => {
        return {
          name: b,
          path: b,
          component: pages[b],
          beforeEnter: (to, from, next) => {
            if (canParticipate(b)) next()
            else {
              next({
                path: from.fullPath,
              })
              Message({
                message: i18n.t('subIb.noPermission'),
                type: 'error',
              })
            }
          },
        }
      }),
    ],
  },
]

const router = new Router({
  mode: 'history',
  routes: [
    ...routes,
    {
      path: '',
      component: () => import('@/views/Layout'),
      children: [
        {
          name: '404',
          path: '*',
          component: () => import('@/views/404'),
        },
      ],
    },
  ],
})

export default router
identity.authorize()
