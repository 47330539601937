import { instance, instanceExternal } from './axios'
import qs from 'qs'

const baseUrl = '/api/'
const cpUrl = '/cp/api/'

const loginUrl = `${baseUrl}login/`
const homeUrl = `${baseUrl}home/`
const withdrawalUrl = `${baseUrl}withdrawal/`
const transferUrl = `${baseUrl}transfer/`
const transferCPUrl = `${cpUrl}transfer/`
const contactUrl = `${baseUrl}contact/`
const commissionUrl = `${baseUrl}commission/`
const tradeaccountUrl = `${baseUrl}tradeaccount/`
const TradeRebateVolumeUrl = `${baseUrl}TradeRebateVolume/`
const foldlineUrl = `${baseUrl}foldline/`
const pieUrl = `${baseUrl}pie/`
const depositUrl = `${cpUrl}deposit/`
const accountUrl = `${cpUrl}account/`
const withdrawalUrlCp = `${cpUrl}withdrawal/`
const userProfileUrl = `${baseUrl}userProfile/`
const ibreportUrl = `${baseUrl}ibreport/`
const reportUrl = `${baseUrl}report/`
const rebateUrl = `${baseUrl}rebate/`
const ibaccountUrl = `${baseUrl}ibaccount/`
const menuUrl = `${baseUrl}menu/`
const unfundedaccountUrl = `${baseUrl}unfundedaccount/`
const googleTranslateBasic = `https://translation.googleapis.com/language/translate/v2?key=AIzaSyCCW3iNEXzwyqDJzqQosBQIDe_2amtzDCo`
const notificationUrl = `${baseUrl}notification/`
const systemUrl = `${baseUrl}system/`
const paymentSelectionUrl = `${baseUrl}payment/`
const mfaUrl = `${baseUrl}mfa/`
const subUrl = `${baseUrl}sub-account/`

/** login */
export const apiTo_login_by_token = headers => instance.post(`${loginUrl}to_login_by_token`, '', headers)
export const apiTo_logout = () => instance.get(`${loginUrl}to_logout`)
export const apiGet_cp_url = () => instance.get(`${loginUrl}get_cp_url`)

/** home */
export const apiSetlanguage = data => instance.post(`${userProfileUrl}setlanguage`, qs.stringify(data))
export const apiQueryRebatesList = () => instance.get(`${homeUrl}queryRebatesList`)
export const apiQueryRebatesBlackList = () => instance.get(`${homeUrl}queryRebatesBlackList`)
export const apiQueryAvailableBalance = data => instance.post(`${homeUrl}queryAvailableBalance`, qs.stringify(data))
export const apiQueryTotalCommission = data => instance.post(`${homeUrl}queryTotalCommission`, qs.stringify(data))
export const apiGetNewOpenUserTotal = data => instance.post(`${homeUrl}getNewOpenUserTotal`, qs.stringify(data))

/** token */
export const apiRequire_token = () => instance.post(`${depositUrl}require_token`)
export const apiAnti_Reuse = () => instance.get(`${baseUrl}token/anti-reuse`)

/** file */
export const apiDelete_file = data => instance.post(`${fileUrl}delete`, data)

export const apiSendEmailVerificationCode = data => instance.post(`${mfaUrl}sendEmailVerificationCode`, data)

/** withdrawal */
export const apiApplyWithdrawal = (data, token) =>
  instance.post(`${withdrawalUrl}applyWithdrawal`, qs.stringify(data), { headers: { token: token } })
export const apiCardInformation = data => instance.get(`${withdrawalUrlCp}queryUnionpayCard`, { param: data })
export const apiGetWithdrawalData = data => instance.post(`${withdrawalUrl}getWithdrawalData`, qs.stringify(data))
export const apiRebateWithdrawBlacklist = () => instance.get(`${withdrawalUrl}queryBlacklist`)
export const apiWithdrawalQueryRateUSDByTargetCurrency = targetCurrency =>
  instance.get(`${withdrawalUrl}queryRateUSDByTargetCurrency/${targetCurrency}`)
export const apiGetWithdrawalBankCode = () => instance.get(`${withdrawalUrl}queryBankCode`)
export const apiGetWithdrawalBankBranchCode = bankCode => instance.get(`${withdrawalUrl}queryAllByBankCode/${bankCode}`)
export const apiCancelWithdraw = data =>
  instance.post(`${withdrawalUrl}cancelWithdrawalOrder?withdrawalId=${data.withdrawalId}`)

/** Payment */
export const apiPaymentSelection = data => instance.post(`${paymentSelectionUrl}info/getPaymentInfoList/`, data)

/** transfer */
export const apiToApplyTransferView = data => instance.post(`${transferUrl}toApplyTransferView`, qs.stringify(data))
export const apiApplyTransfer = (data, token) =>
  instance.post(`${transferUrl}applyTransfer`, qs.stringify(data), { headers: { token: token } })
export const apiRebateTransferBlacklist = () => instance.get(`${transferCPUrl}queryBlacklist`)

/** contact */
export const apiOwnerinfo = () => instance.post(`${contactUrl}ownerinfo`)
export const apiContactinfo = data => instance.post(`${contactUrl}contactinfo`, qs.stringify(data))

/** commission */
export const apiApplyCommission = data => instance.post(`${commissionUrl}applyCommission`, qs.stringify(data))

/** tradeaccount */
export const apiGetNearestOpenAccount = data =>
  instance.post(`${tradeaccountUrl}getNearestOpenAccount`, qs.stringify(data))
export const apiGetPendingAccount = data => instance.post(`${tradeaccountUrl}getPendingAccount`, data)

export const apiCheckEquity = data => instance.get(`${accountUrl}check_equity?mt4Account=${data}`)

/** TradeRebateVolume */
export const apiQueryRebateVolumeList = data =>
  instance.post(`${TradeRebateVolumeUrl}queryRebateVolumeList`, qs.stringify(data))

/** foldline */
export const apiFoldline = (url, data) => instance.post(`${foldlineUrl}${url}`, qs.stringify(data))

/** pie */
export const apiQueryPieTotalVolumeByGoods = data =>
  instance.post(`${pieUrl}queryPieTotalVolumeByGoods`, qs.stringify(data))

/** ibreport */
export const apiQueryIbReportData = data => instance.post(`${ibreportUrl}queryIbReportData`, data)

/** report */
export const apiRebate_report = data => instance.post(`${reportUrl}rebate-report`, data)
export const apiRebatePayerTradeDetails = data => instance.post(`${reportUrl}rebate-report/all-data`, data)
export const apiPayer_trade_details = data => instance.post(`${reportUrl}rebate-report/payer-trade-details`, data)
export const apiGet_instruments = data =>
  instance.get(`${reportUrl}get-instruments`, {
    params: data,
  })

/** rebate */
export const apiRebate = (url, data) => instance.post(`${rebateUrl}${url}`, qs.stringify(data))

/** ib account */
export const apiIbAccounts = (url, data) => instance.post(`${ibaccountUrl}${url}`, qs.stringify(data))

/** menu */
export const apiLeads = data => instance.post(`${menuUrl}leads`, data)

/** unfunded account */
export const apiGetAccsByUrl = (url, data) => instance.post(`${unfundedaccountUrl}${url}`, qs.stringify(data))

/** google translation */
export const apiGetTranslation = data =>
  instanceExternal.get(`${googleTranslateBasic}`, {
    params: data,
  })

/** notification */
export const apiGetNotifications = ({ country }) => instance.get(`${notificationUrl}enabled?country=${country}`)

/** systemUrl */
export const apiInformation = () => instance.get(`${systemUrl}information`)

/** Subib */
export const apiGetHistory = data =>
  instance.get(
    `${subUrl}history/page?pageNo=${data.currentPage}&pageSize=${data.pageSize}${
      data.filterAccount ? `&subId=${data.filterAccount}` : ''
    }`
  )
export const apiGetSubAccounts = () => instance.get(`${subUrl}list`)
export const apiUpdateAccount = data => instance.post(`${subUrl}edit`, data)
export const apiEnableDeleteAccount = data => instance.post(`${subUrl}enable`, data)
export const apiDeleteAccount = data => instance.post(`${subUrl}enable`, data)
export const apiUpdatePassword = data => instance.post(`${subUrl}reset-password`, data)

export const apiEncryptPublicKey = (token = null) => instance.get(`${cpUrl}keyPair/pk?token=${token}`)

/**session */
export const apiGetSessionId = () => instance.get(`${baseUrl}tool/session-id`)
