export default {
  en: 'Englisch',
  zh: '中文',
  home: {
    RebateAccountNumber: 'Rabatt-kontonummer',
    totalCommission: 'Gesamtprovision',
    APPLY_FOR_A_REBATE: 'Rabatt beantragen',
    availableBalance: 'Verfügbares guthaben',
    WITHDRAW_REBATE: 'Auszahlungen',
    TRANSFER_REBATE_INTO_YOUR_TRADING_ACCOUNT: 'Überweisung',
    UPDATE: 'Aktualisieren',
    REBATE: 'Rabatt',
    TOTAL_VOLUME: 'Gesamtvolumen',
    NET_FUNDING: 'Netto-finanzierung',
    DEPOSITS: 'Einzahlungen',
    WITHDRAW: 'Auszahlungen',
    OPEND_ACCOUNT: 'Eröffnetes konto',
    PERFORMANCE: 'Performance',
    ACCOUNT_MANAGER: 'Kundenbetreuung',
    MOST_TRADED_INSTRUMENTS: 'Meist gehandelte instrumente',
    TOP_PERFORMING_ACCOUNTS_FOR_THIS_MONTH: 'Konten mit der besten performance in diesem monat',
    RECENTLY_OPENED_ACCOUNTS: 'Kürzlich eröffnete konten',
    Account: 'Konto',
    Name: 'Name',
    Volume: 'Volumen',
    Rebate: 'Rabatt',
    Balance: 'Guthaben',
    ContactClient: 'Kundenkontakt',
    applyRebateSuccessTip:
      'Herzlichen Glückwunsch, wir haben Ihren Rabattantrag erhalten. | {amount} wird in Kürze auf das Rabattkonto {account} überwiesen. | Sobald die Bearbeitung abgeschlossen ist, können Sie Ihr Geld abheben oder überweisen.',
    blacklist:
      'Für dieses Konto können derzeit keine Rabatte angewendet werden. Bitte wenden Sie sich an Ihren Account Manager.',
  },
  liveChat: { header: 'Live-Chat' },
  ibAccounts: { ibAccount: 'IB-konto', subIbs: 'IB-unterkonto' },
  withdraw: {
    disclaimer:
      '<li>Haftungsausschluss:</li>\n      <li>Bitte beachten Sie, dass der Wechselkurs für den Verkauf von Kryptowährungen in Fiat-Währungen ausschließlich von Banxa, einem externen Zahlungsabwickler, festgelegt wird. Alphatick hat keinen Einfluss oder Kontrolle über den von Banxa angebotenen Wechselkurs. Daher entziehen sich jegliche Schwankungen oder Veränderungen des Wechselkurses unserer Kontrolle und sind ausschließlich auf die Richtlinien von Banxa und die Marktbedingungen zurückzuführen.</li>\n      <li>Wir empfehlen Ihnen, den von Banxa angebotenen Wechselkurs und alle damit verbundenen Gebühren oder Kosten zu überprüfen, bevor Sie mit Ihrer Transaktion fortfahren. Bitte beachten Sie, dass Alphatick nicht für finanzielle Verluste oder Unstimmigkeiten verantwortlich gemacht werden kann, die sich aus der Nutzung der Dienste von Banxa oder eines anderen Drittanbieters für Zahlungen ergeben.</li>\n      <li>Vielen Dank für Ihr Verständnis und Ihre Kooperation.</li>\n     ',
    default: {
      selectCC: 'Kreditkarte wählen',
      selectBA: 'Bankkonto wählen',
      anotherCC: 'Kreditkarte manuell eingeben',
      anotherBA: 'Bankkonto hinzufügen',
      remember: 'Mein Konto merken',
      failed: 'Auszahlung fehlgeschlagen. Bitte versuchen Sie es später erneut.',
      tokenExpired: 'Token ist abgelaufen',
    },
    transfer: {
      info: '{platform} wird die bankgebühr für <u>EINE</u> Auszahlung pro monat zurückerstatten. Für weitere auszahlungen innerhalb des monats fällt eine bankgebühr von 20 einheiten ihrer auszahlungswährung an.',
      label: {
        upload:
          'Bitte laden sie eine kopie ihres kontoauszugs hoch (aktuell für die letzten 3 Monate).',
      },
      formValidation: { upload: 'Bitte laden sie einen kontoauszug hoch.' },
    },
    alert: {
      title:
        'Bevor Sie Ihren Auszahlungsantrag einreichen, beachten Sie bitte die folgenden Hinweise:',
      desc1:
        'Aufgrund von AML-Gesetzen muss die methode, die Sie zur auszahlung verwenden, dieselbe sein wie die, die sie für ihre einzahlung verwendet haben. Wenn der auszahlungsbetrag weniger als {units} einheiten der basiswährung ihres kontos beträgt, wird ihnen von der bank eine bearbeitungsgebühr von {charge} einheiten berechnet.',
    },
    cryptoAlert:
      'Bitte beachten Sie, dass wir keine Einzahlungen oder Auszahlungen über BUSDT vornehmen können. Bitte stellen Sie sicher, dass die Adresse und die Kryptowährung mit der von uns akzeptierten Chain und Währung übereinstimmen, sonst könnten Sie die Gelder verlieren.',
    withdrawlFeeAlert:
      'Wenn der auszahlungsbetrag weniger als {units} einheiten der basiswährungihres kontos beträgt, wird ihnen von der bank eine bearbeitungsgebühr von {charge} einheiten berechnet.',
    REBATE_WITHDRAW_REQUEST: 'Antrag auf rabattauszahlung',
    PleaseSelectDesiredWithdrawMethod: 'Bitte wählen sie die gewünschte auszahlungssmethode',
    BankName: 'Bank name',
    bankCode: 'Bankleitzahl',
    bankBranchCode: 'Filialcode',
    BankAccountsName: 'Bankkonto name',
    FasaPayAccountName: 'FasaPay kontoname',
    AustralianBankName: 'Australische bank name',
    BankAccountNumber: 'Bankkontonummer',
    bankAccountCurrency: 'Währung des Bankkontos',
    FasaPayAccountNumber: 'FasaPay-kontonummer',
    BankAccountBeneficiary: 'Bankkonto begünstigter',
    BSB: 'BSB',
    skrillEmail: 'Skrill E-Mail',
    NetellerEmail: 'Neteller-E-Mail',
    BitcoinEmail: 'Bitcoin-Wallet adresse',
    USDTEmail: 'Tether-Wallet adresse',
    perfectMoneyEmail: 'Perfect Money E-Mail',
    BankBranch: 'Bankfiliale',
    Province: 'Provinz',
    City: 'Stadt',
    BankAddress: 'Bank adresse',
    AccountNumberIBAN: 'Kontonummer/IBAN',
    BankBeneficiaryName: 'Bank name des begünstigten',
    BankAccountBeneficiaryName: 'Bankkonto name des begünstigten',
    AccountHoldersAddress: 'Adresse des kontoinhabers',
    SelectWithdrawCard: 'Wählen sie ihre auszahlungskarte',
    Swift: 'Swift',
    SwiftAud: 'Swift (Nicht-AUD-Konten)',
    ABA_SortCodeABA: 'ABA/Sort-Code',
    name: 'Name',
    phone: 'Telefonnummer',
    address: 'Adresse',
    email: 'E-Mail',
    ImportantNotes: 'Wichtige hinweise',
    YourPaymentGreaterThanAvailableBalance: 'Ihre zahlung ist höher als das verfügbare guthaben',
    CannotUseMoreThanTwoDecimalValues:
      'Sie können keine zahlen und negativen zahlen mit mehr als zwei dezimalstellen verwenden',
    YourPaymentIsBeingProcessed: 'Ihr auszahlungsantrag wurde eingereicht',
    Itcannotbe0: 'Es kann nicht 0 sein',
    noCardWarn:
      'Sie haben keine verfügbare karte für die auszahlung, bitte verknüpfen Sie ihre karte zuerst in den auszahlungsdetails',
    amtLarger: 'Der betrag kann nicht null sein und muss größer oder gleich ${minLimit} sein',
    blackList:
      'Leider können Sie derzeit keinen Auszahlungsantrag einreichen. Bitte kontaktieren Sie {supportEmail} für weitere Informationen',
    NetellerChargeAlert: 'Neteller berechnet für jede Auszahlung eine Transaktionsgebühr von 2%.',
    FasaPayChargeAlert: 'FasaPay berechnet für jede Auszahlung eine Transaktionsgebühr von 0.5%.',
    SkrillChargeAlert: 'Skrill erhebt eine Transaktionsgebühr von 1% für jede Auszahlung.',
    status: {
      withdrawalSubmitted: 'Abgesendet',
      withdrawalCanceled: 'Abgebrochen',
      withdrawalAuditing: 'In bearbeitung',
      withdrawalRejected: 'Abgelehnt',
      withdrawalAccepted: 'In bearbeitung',
      withdrawalPayFailed: 'Gescheitert',
      withdrawalSuccess: 'Verarbeitet',
      withdrawalFailed: 'Gescheitert',
      withdrawalPending: 'Ausstehend',
      withdrawalPartialFailed: 'Gescheitert',
      transferSubmitted: 'In bearbeitung',
      transferSuccess: 'Verarbeitet',
      transferRejected: 'Abgelehnt',
      transferFailed: 'Gescheitert',
      transferProcessing: 'In bearbeitung',
      withdrawalReversedfailed: 'Umgekehrtes Versagen',
    },
    formErrorMsg: {
      bankName: 'Bankname ist erforderlich',
      bankCodeReq: 'Bankleitzahl ist erforderlich',
      bankBranchCodeReq: 'Filialcode ist erforderlich',
      bsb: 'BSB ist erforderlich',
      beneName: 'Bank name des Begünstigten ist erforderlich',
      accNum: 'Bankkontonummer ist erforderlich',
      swift: 'Swift ist erforderlich',
      bankAddress: 'Bankadresse ist erforderlich',
      holderAddress: 'Inhaberadresse ist erforderlich',
      amt: 'Betrag ist erforderlich',
      firstSixDigits: 'Bitte geben sie die ersten 6 Ziffern ein',
      lastFourDigits: 'Bitte geben sie die letzten 4 Ziffern ein',
      branchName: 'Filialname ist erforderlich',
      email: 'E-Mail ist erforderlich',
      accName: 'Kontoname ist erforderlich',
      province: 'Bank provinz ist erforderlich',
      city: 'Bank stadt ist erforderlich',
      bankCard: 'Bitte wählen Sie eine Karte',
      emailFormat: 'Bitte korrekte E-Mail Adresse eingeben',
      bitcoin: 'Bitcoin-Wallet-Adresse ist erforderlich',
      bitcoinAddressLength: 'Bitcoin-Wallet-Adresse muss zwischen 26 und 42 Zeichen betragen',
      usdt: 'USDT-Wallet-Adresse ist erforderlich',
      dynamicReq: '{fieldname} ist erforderlich',
      beneficiaryNameReq: 'Bankbegünstigtenname ist erforderlich',
    },
    beneficiaryName: 'Begünstigter Name',
  },
  rebate: {
    status: { processed: 'Verarbeitet', processing: 'In bearbeitung', rejected: 'Abgelehnt' },
  },
  transfer: {
    REBATE_TRANSFER_REQUEST: 'Rabatt-überweisungsantrag',
    transferAmountIsGreaterThanAvailableBalance:
      'Ihr überweisungsantrag ist größer als das verfügbare guthaben',
    yourRebateTransferHasBeenProcessed: 'Ihre rabatt-überweisung wurde eingereicht',
    PleaseEnterTransferInformation: 'Bitte geben Sie genaue überweisungsinformationen ein',
    tradingAccount: 'Trading-konto',
    rebateAccount: 'Rabatt-konto',
    account: 'Konto',
  },
  fileUploader: {
    supportedFormat: 'Unterstützte dateitypen: png, jpg, jpeg, bmp, pdf, doc, docx',
    supportedFormatCustom: 'Unterstützte Dateitypen: {files}',
    maxSize: 'Maximale dateigröße beim hochladen: {maxSize}MB',
    maxFileNum: 'Maximale dateianzahl: {maxFileNum}',
    failedFileExtension: 'Bitte laden Sie den richtigen Dateityp hoch',
  },
  common: {
    liveChat: { desc: '<li><p>Brauchen sie hilfe?</p><span>Live- Chat</span></li>' },
    lang: {
      english: 'Englisch',
      chinese: 'Chinesisch',
      french: 'Französisch',
      thai: 'Thailändisch',
      german: 'Deutsch',
      spanish: 'Spanisch',
      malay: 'Malaiisch',
      viet: 'Vietnamesisch',
      indo: 'Indonesisch',
      arabic: 'Arabisch',
    },
    button: { confirm: 'Bestätigen', cancel: 'Abbrechen', selectFile: 'Datei auswählen' },
    withdrawChannel: {
      banktransfer: 'Banküberweisung',
      aus: 'Banküberweisung - Australien',
      int: 'Banküberweisung - International',
      chn: 'Banküberweisung - China',
      skrill: 'Skrill',
      skrill_neteller: 'Skrill/Neteller',
      neteller: 'Neteller',
      unionpay: 'UnionPay',
      fasapay: 'Fasapay',
      thailandinstantbankwiretransfer: 'Thailand Sofortige Banküberweisung',
      malaysiainstantbankwiretransfer: 'Malaysia Sofortige Banküberweisung',
      indonesiainstantbankwiretransfer: 'Indonesien Sofortüberweisung',
      philippinesinstantbankwiretransfer: 'Philippinische Sofortige Banküberweisung',
      banktransferbpaypolipay: 'Banküberweisung/BPay/PoliPay',
      banktransferbpaypolipayaustralia: 'Banküberweisung/BPay/PoliPay (Australien)',
      banktransferbpaypolipayinternational: 'Banküberweisung/BPay/PoliPay (International)',
      cryptocurrencybitcoin: 'Kryptowährung-Bitcoin',
      cryptocurrencyusdt: 'Kryptowährung-USDT',
      vietnaminstantbankwiretransfer: 'Vietnamesische Sofortüberweisung',
      laosinstantbankwiretransfer: 'Laos Sofortige Banküberweisung',
      indiainstantbanktransfer: 'Indien Sofortige Banküberweisung',
      brazilbanktransfer: 'Brasilianische Sofortige Banküberweisung',
      mexicobanktransfer: 'Mexiko Sofortige Banküberweisung',
      internetbankingmexico: 'Internet-Banking (Mexiko)',
      internetbankingbrazil: 'Internet-Banking (Brasilien)',
      transfer: 'Überweisung auf Konten',
      hongkongbanktransfer: 'Lokale Banküberweisung (Hongkong)',
    },
    country: { international: 'International', australia: 'Australien' },
    field: { country_region: 'Land/Region', yes: 'Ja', no: 'Nein' },
    keys: {
      SUBMIT: 'Absenden',
      FROM: 'Von',
      UPDATE: 'Aktualisieren',
      TO: 'An',
      DATE: 'Datum',
      pleaseSelect: 'Bitte auswählen',
      amount: 'Betrag',
      rebateAccount: 'Rabatt-konto',
      comingSoon: 'demnächst',
      tradingHistory: 'Trading-historie',
      noData: 'Keine daten',
      NAME: 'Name',
      EMAIL: 'E-Mail',
      PHONE: 'Telefonnummer',
      ACCTYPE: 'Konto-typ',
      PLATFORM: 'Plattform',
      BASECURRENCY: 'Basiswährung',
      DATERANGE: 'Datumsbereich',
      STATUS: 'Status',
      DEST: 'Ziel',
      ACCNUM: 'Kontonummer',
      BALANCE: 'Guthaben',
      NONE: 'Keine',
      ALL: 'Alle',
      PROFIT: 'Gewinn',
      MARGINLEVEL: 'Margin-Level (%)',
      ACCOUNTEQUITY: 'Kontokapital',
      CREDIT: 'Gutschrift',
      createTime: 'Übermittlungszeit',
      lastUpdateTime: 'Verarbeitete Zeit',
    },
    dateRange: {
      today: 'Heute',
      yesterday: 'Gestern',
      lastSevenDays: 'Letzte 7 tage',
      lastThirtyDays: 'Letzte 30 tage',
      weekToDate: 'Woche bis heute',
      monthToDate: 'Monat bis heute',
      quarterToDate: 'Quartal bis heute',
      previousWeek: 'Vorherige woche',
      previousMonth: 'Vorheriger monat',
      previousQuarter: 'Vorheriges quartal',
    },
    products: { forex: 'Forex', commodities: 'Rohstoffe', indices: 'Indizes', crypto: 'Crypto' },
  },
  report: {
    rebate: {
      column: {
        name: 'Name',
        accountNumber: 'Kontonummer',
        accountType: 'Konto-typ',
        volume: 'Volumen',
        rebate: 'Rabatt',
        totalRebate: 'Gesamtrabatt',
        date: 'Datum',
        instrument: 'Instrument',
        notionalvalue: 'Nominalwert',
      },
      dropdown: { allInstruments: 'Alle instrumente' },
      others: {
        rebate: 'Rabatt',
        instrumentsTraded: 'Gehandelte instrumente',
        lots: 'Lots',
        totalRebate: 'Gesamtrabatt',
      },
    },
  },
  ibReport: {
    netFunding: 'Netto-finanzierung',
    deposits: 'Einzahlungen',
    withdraw: 'Auszahlungen',
    openedAccs: 'Eröffnete konten',
    funding: 'Finanzierung',
  },
  lead: { header: 'Leads', ibAccount: 'IB-Konto', demoAccounts: 'Demo-Konten' },
  pendingAccounts: {
    NOTES: 'Anmerkungen',
    errorDoc:
      'Die folgenden antragsteller haben das online-antragsformular ausgefüllt, müssen aber noch einen Identifikationsnachweis (ID) erbringen, um ihr konto abschließen zu können.',
    acceptedDocType: 'Akzeptierte formen der ID sind:',
    primaryDoc: 'Primäre-ID',
    idList: [
      'Eine kopie ihres gültigen internationalen reisepasses (Unterschriftenseite)',
      'Eine kopie ihres staatlich ausgestellten personalausweises',
      'Eine kopie ihres gültigen führerscheins',
    ],
    address: 'Sekundäre-ID',
    addressList: [
      'Eine kopie einer aktuellen rechnung eines öffentlichen versorgungsunternehmens (z. B. Gas oder Strom), die ihren namen und ihre wohnadresse enthält (nicht älter als 6 Monate)',
      'Eine kopie ihrer kreditkartenabrechnung oder ihres kontoauszugs (keine Online-ausdrucke)',
    ],
    tip: 'Bitte fordern sie bewerber auf, eine kopie EINES der akzeptierten primären und sekundären ID-dokumente an {mailTo} zu senden.',
  },
  rebatePaymentHistory: {
    rebateHistory: 'Rabatte',
    withdrawHistory: 'Auszahlungen',
    transferHistory: 'Überweisungen',
  },
  transferHistory: {
    payStatus: {
      processing: 'In bearbeitung',
      paid: 'Bezahlt',
      unpaid: 'Unbezahlt',
      rejected: 'Abgelehnt',
    },
  },
  referralLinks: {
    ibAcc: 'IB-konto',
    campLang: 'Kampagnen-sprache',
    cpy: 'Kopie',
    alreadyCPY: 'Kopiert!',
    liveAcc: 'Live-konto',
    demoAcc: 'Demo-konto',
    homePage: 'Startseite',
  },
  settings: {
    header: 'Informationen zum kunden',
    name: 'Name des kunden',
    emailAdd: 'E-Mail-Adresse',
    phone: 'Telefonnummer',
    address: 'Adresse',
  },
  unfundedAcc: {
    neverFundedAccs: 'Nie finanzierte konten',
    prevFundedZero: 'Zuvor gedeckt (kein guthaben)',
  },
  header: { bckToCP: 'Zurück zum kundenportal', logout: 'Ausloggen' },
  multiib: { level: 'Ebene {level}', noib: 'Es gibt keine Sub-IBs unter diesem konto.' },
  subIb: {
    title: 'Geteiltes IB-Konto',
    account: 'Konto',
    accountNote: 'Maximal 10 geteilte IB-Konten',
    subCreateAcc: 'Hinzufügen',
    subCreateIbAcc: 'Neues geteiltes IB-Konto hinzufügen',
    editAccount: 'Geteiltes IB-Konto bearbeiten',
    copied: 'Geteiltes IB-Konto kopiert',
    no: 'Nummer',
    permission: 'Berechtigung',
    remark: 'Bemerkung',
    status: 'Status',
    confirm: 'Bestätigen',
    cancel: 'Abbrechen',
    noPermission: 'Keine Berechtigung für dieses Konto',
    setting: 'Einstellungen',
    viewMore: 'Mehr anzeigen',
    editPassword: 'Passwort zurücksetzen',
    resetPassword: 'Bitte geben Sie das neue Passwort ein',
    delete: 'Löschen',
    emailAddress: 'E-Mail-Adresse',
    emailFormat: 'Bitte geben Sie eine gültige E-Mail-Adresse ein',
    permissionTooltip:
      'Sie können die folgenden Berechtigungen für gemeinsame Konten erlauben oder nicht:<li>• Kontodaten: Anzeigen von Kontodatenseiten (IB-Konten, Kürzlich geöffnete Konten). Die Daten werden ausgeblendet, wenn die Berechtigung nicht erteilt ist. </li><li>• IB-Berichtsdaten: Anzeigen von IB-Datenseiten (Dashboard, mehrstufiges IB – Konto). Die Daten werden ausgeblendet, wenn die Berechtigung nicht erteilt ist. </li><li>• Geldberechtigung: IB-Fonds betreiben (Rabatt anwenden, Rabatt übertragen, Rabatt abheben), Rückvergütung anzeigen (Rückvergütungsbericht, Saldo und Fondsdatensätze). Operationen können nicht durchgeführt werden, wenn die Berechtigung nicht erteilt ist. </li>',
    refresh: 'Aktualisieren',
    historyEnd: 'Die gesamte Historie wurde angezeigt',
    refreshTips: 'Historie aktualisiert',
    password: 'Passwort',
    isActive: 'Aktiviert',
    updatedTime: 'Zuletzt aktualisiert',
    accWarning: '10 Konten existieren bereits, es können keine weiteren hinzugefügt werden.',
    isInactive: 'Deaktiviert',
    passwordTooltip:
      '<li>• 8-16 Zeichen</li><li>• Mischung aus Groß- und Kleinbuchstaben (A-Z, a-z), Zahlen (0-9) und Sonderzeichen (z. B.: !@#S%^&)</li>',
    resetPasswordTooltip: 'Das alte Passwort wird ungültig und auf das neue Passwort zurückgesetzt',
    all: 'Alle',
    chooseAccount: 'Konto auswählen',
    permissionField: 'Berechtigung des geteilten IB-Kontos',
    historyTitle: 'Geteilte Kontohistorie',
    device: 'Gerät',
    emailExisting:
      'E-Mail existiert bereits, bitte versuchen Sie es mit einer anderen Email Adresse',
    remarkPlaceholder: 'Sie können den Zweck des Kontos oder den Kontoinhaber speichern',
    passwordRequired: 'Passwort ist erforderlich',
    deleteDesc:
      'Sie können das geteilte IB-Konto nach der Löschung nicht mehr anmelden. Möchten Sie es löschen?',
    updateWithdrawalDesc:
      'Nach Erteilung der Fondsgenehmigung kann das geteilte IB-Konto das Guthaben Ihres Haupt-IB-Kontos übertragen und abheben. Möchten Sie dies erlauben?',
    loginAction: 'Einloggen',
    applyRebateAction: 'Rabatt anwenden',
    deleteSuccess: 'Geteiltes IB-Konto wurde gelöscht.',
    enabledSuccess: 'Geteiltes IB-Konto wurde aktiviert.',
    disabledSuccess: 'Geteiltes IB-Konto wurde deaktiviert.',
    addSuccess: 'Geteiltes Konto erstellt',
    modifiedSuccess: 'Erfolgreich geändert',
    withdrawRebateAction: 'Rabatt auszahlen: Von {from} bis {to}, Betrag {amount}',
    passwordSuccess: 'Passwortrücksetzung erfolgreich',
    transferRebateAction: 'Rabatt übertragen: Von {from} bis {to}, Betrag {amount}',
    newPwAccFormat:
      'Ihre Passwortkombination muss zwischen 8 und 16 Zeichen lang sein und sollte mindestens einen Großbuchstaben (AZ), einen Kleinbuchstaben (az), eine Zahl (0-9) und ein Sonderzeichen (!@#$%^&*) enthalten. . Wenn Ihr neues Passwort nicht aktualisiert werden kann, bestätigen Sie bitte erneut, dass die Kombination die Anforderung erfüllt.',
    clientData: 'Kontodaten',
    ibData: 'IB-Berichtsdaten',
    fundsPermission: 'Geldberechtigung',
  },
  menu: {
    dashboard: 'Dashboard',
    ibReport: 'IB-Bericht',
    rebateReport: 'Rabatt-Bericht',
    ibAccs: 'IB-Konten',
    iblevel: 'Multi-level-IB',
    leads: 'Leads',
    pendingAccs: 'Ausstehende konten',
    unfundedAccs: 'Ungedeckte konten',
    payHistory: 'Transaktionshistorie',
    ibProfile: 'IB-Profil',
    refLinks: 'Empfehlungslinks',
    contactUs: 'Kontakt',
    IB_PORTAL: 'IB-Portal',
    FEATURES: 'Merkmale',
  },
  footer: {
    riskWarn:
      'Risikowarnung: Das trading mit Forex und CFDs ist mit erheblichen risiken verbunden und kann zum verlust ihres investierten\n  kapitals führen. Das traden von hebelprodukten ist möglicherweise nicht für alle anleger geeignet. Bevor sie traden, berücksichtigen sie bitte\n  ihren erfahrungsstand, ihre anlageziele und lassen sie sich gegebenenfalls von einem unabhängigen finanzberater beraten.\n  Bitte lesen sie unsere rechtlichen dokumente auf unserer Website und stellen sie sicher, dass sie die risiken vollständig verstehen, bevor sie irgendwelche\n  trading-entscheidungen treffen.',
  },
  responseMsg: {
    410: 'Parametervalidierung fehlgeschlagen',
    411: 'Falsches passwort',
    420: 'Benutzer existiert nicht',
    421: 'Passwortrücksetzung verweigert',
    490: 'Bitte geben sie keine doppelten karteninformationen ein',
    500: 'Es ist ein Fehler aufgetreten.',
    501: 'Antrag fehlgeschlagen. Bitte versuchen Sie es später erneut.',
    505: 'Datei-Upload fehlgeschlagen',
    520: 'Anmeldung fehlgeschlagen. Bitte versuchen Sie es erneut.',
    521: 'Es ist bereits ein Benutzer auf diesem gerät angemeldet!',
    522: 'Bitte melden sie sich erneut an',
    523: 'Bitte melden sie sich erneut an',
    524: 'Leider wurde ihre E-Mail-Adresse nicht erkannt',
    525: 'Falscher benutzername oder falsches passwort',
    527: 'Benutzer ist kein IB',
    528: 'Benutzer existiert nicht',
    529: 'Falscher benutzername oder falsches Passwort',
    540: 'Ihr trading-konto konnte nicht gefunden werden',
    541: 'Kann rabatt-konto nicht finden',
    542: 'Sie dürfen kein zusätzliches Konto eröffnen',
    544: 'Sie können kein weiteres Konto beantragen, bis Ihr Identitätsnachweis verifiziert wurde',
    550: 'Falsche kontonummer',
    551: 'Ihr guthaben ist nicht ausreichend',
    553: 'Sie können aufgrund des Guthabens auf Ihrem Konto nicht auszahlen/überweisen. Bitte kontaktieren sie {email} für weitere Hilfe.',
    554: 'Der angeforderte Betrag ist Null oder nicht gültig',
    562: 'Wir konnten ihre zahlung nicht verarbeiten, bitte versuchen sie es erneut. Wenn das problem weiterhin besteht, kontaktieren sie uns bitte per Live-Chat oder E-Mail {email}',
    564: 'Land stimmt nicht mit dem Zahlungskanal überein',
    565: 'Falsche bankleitzahl',
    566: 'Zahlungsmethode nicht gefunden',
    581: 'Sie haben sich erfolgreich für die promotion angemeldet. Bitte beachten Sie, dass sie eine einzahlung vornehmen müssen, um Geld auszahlen zu können.',
    584: 'Sie haben keine anwendbaren Rabatte',
    590: 'Transfer-out-konto nicht gefunden',
    591: 'Kann einbuchungskonto nicht finden',
    593: 'Wir können Ihren Geldtransfer von dem ausgewählten Konto nicht bearbeiten, da Ihr Konto Guthaben enthält.',
    594: 'Geldtransfer von diesem Konto ist nicht erlaubt',
    1101: '10 Konten existieren bereits, es können keine weiteren hinzugefügt werden.',
    1102: 'Die Aktivierung ist nicht möglich, da das Konto als Live- oder Demokonto erstellt wurde.',
    session_timeout: 'Session-Timeout, weiterleitung zur anmeldeseite.',
  },
};
