import { JWEEncrypt, JWEDecrypt } from '@/util/cryptoUtils'
import { Base64 } from 'js-base64';
import { encrypt } from '@/util/JSEncrypt';
import { apiEncryptPublicKey } from '@/resource';

const expirationMinutes = 0.5; // 30s

const queryPayload = async (accessToken) => {
  const publicKeyRes = await apiEncryptPublicKey(accessToken);

  const encryptToken = encrypt(accessToken, publicKeyRes.data.data);

  return {
    accessToken: encryptToken,
    pk: publicKeyRes.data.data,
  }
}

const createSecureURL = async (baseURL, accessToken) => {
  const payload = await queryPayload(accessToken);

  const encryptedAccessToken = await JWEEncrypt(payload, expirationMinutes);

  return `${baseURL}?accessToken=${encodeURIComponent(encryptedAccessToken)}`;
}

const parseSecureURL = async (encryptedAccessToken) => {

  if (!encryptedAccessToken) {
      throw new Error('Missing accessToken parameter');
  }

  let payload = {};

  // payload = await JWEDecrypt(encryptedAccessToken);

  // 兼容上线后原先base64过的token，后面干掉该代码
  try {
    payload = await JWEDecrypt(encryptedAccessToken);
  } catch (err) {
    payload = await queryPayload(Base64.decode(encryptedAccessToken));
  }

  // 验证过期时间
  const now = Math.floor(Date.now() / 1000);
  if (payload.exp && payload.exp < now) {
      throw new Error('accessToken has expired');
  }

  return payload;
}

export { createSecureURL, parseSecureURL }
