import { rsa, md5 } from '@/util/index.js'
import { Message } from 'element-ui'
import i18n from '@/lang/index'
import moment from 'moment'

const mockPermissionList = () => {
  return [
    {
      label: i18n.t('subIb.clientData'),
      name: 1,
    },
    {
      label: i18n.t('subIb.ibData'),
      name: 2,
    },
    {
      label: i18n.t('subIb.fundsPermission'),
      name: 3,
    },
  ]
}
const defaultVariableMapping = {
  depositWithdrawal: 3,
  ibAccount: 2,
  clientData: 1,
}

const getAccountListingResponse = response => {
  return response.map(item => {
    return {
      ...getAccountResponse(item),
    }
  })
}

const getAccountResponse = response => {
  const existingPermission = response?.permissionIds
    ? response.permissionIds.filter(element => mockPermissionList().some(item => item.name === element))
    : []
  return {
    email: response?.email || '',
    password: response?.password || '',
    permission: existingPermission || [],
    permissionString:
      existingPermission.map(item => (item = mockPermissionList().find(value => item == value.name)?.label)) || [],
    subStatus: response?.enabled || false,
    remark: response?.remark || '',
    id: response?.id ? response.id : null,
    isDel: response?.isDel || false,
  }
}

const updateAccountRequest = (request, existing) => {
  return existing
    ? {
        email: rsa(request.email),
        remark: request.remark,
        permissionIds: request.permission,
        enabled: request.subStatus || false,
        id: existing.id,
      }
    : {
        email: rsa(request.email),
        password: md5(request.password),
        remark: request.remark,
        permissionIds: request.permission,
        enabled: request.subStatus || false,
      }
}

const deleteRequest = request => {
  return {
    id: request.id,
    isDel: true,
  }
}

const updateEnableRequest = request => {
  return {
    id: request.id,
    enabled: !request.subStatus,
  }
}

const updatePasswordRequest = (request, existing) => {
  console.log(request?.password)
  console.log(existing?.id)
  return {
    id: existing?.id,
    password: md5(request?.password),
  }
}

const mapOperation = (operation, event) => {
  switch (operation) {
    case 'transfer':
      return i18n.t('subIb.transferRebateAction', {
        from: event?.fromAccount,
        to: event?.toAccount,
        amount: event?.amount,
      })
    case 'commission':
      return i18n.t('subIb.applyRebateAction')
    case 'withdraw':
      return i18n.t('subIb.withdrawRebateAction', {
        from: event?.fromAccount,
        to: event?.withdrawMethod,
        amount: event?.amount,
      })
    default:
      return i18n.t('subIb.loginAction')
  }
}

const getHistoryListingResponse = response => {
  return response.map(item => {
    return {
      ...getHistoryResponse(item),
    }
  })
}

const getHistoryResponse = response => {
  let timeStamp = []
  if (response && response.createTime) {
    const currentDate = moment(response.createTime)
    const formattedDate = currentDate.format('MM/DD/YYYY HH:mm:ss')

    timeStamp = formattedDate.split(' ')
  }
  return {
    content: mapOperation(response?.operateType, response?.eventInfo),
    timestamp: i18n.t('subIb.emailAddress'),
    time: timeStamp[1],
    date: timeStamp[0],
    desc: `${response?.email}  ${i18n.t('subIb.device')}: ${response?.device}`,
  }
}

function showErrorMessage() {
  return Message({
    message: i18n.t('responseMsg.500'),
    type: 'error',
  })
}

export {
  showErrorMessage,
  mockPermissionList,
  defaultVariableMapping,
  getAccountResponse,
  updateAccountRequest,
  getAccountListingResponse,
  deleteRequest,
  updatePasswordRequest,
  getHistoryListingResponse,
  getHistoryResponse,
  updateEnableRequest,
}
