export default {
  en: 'English',
  zh: '中文',
  fr: 'Français',
  home: {
    NewPortal: 'Nouveau 怕ortail',
    SecureIBPortal: 'Portail IB sécurisé',
    DASHBOARD: 'Tableau de bord',
    MARKETING_TOOLS: 'Outils marketing',
    IB_REPORT: 'Rapport de IB',
    REBATE_REPORT: 'Rapport de bonus',
    IB_ACCOUNTS: 'Comptes IB',
    PAYMENT_HISTORY: 'HISTORIQUE DES TRANSACTIONS',
    IB_FAQ: 'Fréquemment posées de IB',
    RebateAccountNumber: 'Numéro de bonus',
    totalBalance: 'Solde totale',
    totalCommission: 'Bonus totale',
    APPLY_FOR_A_REBATE: 'Demander un bonus',
    availableBalance: 'Solde disponible',
    WITHDRAW_REBATE: 'Se désister',
    TRANSFER_REBATE_INTO_YOUR_TRADING_ACCOUNT: 'Transfert',
    MonthtoDate: 'Le mois courant',
    UPDATE: 'Mise à jour',
    REBATE: 'Bonus',
    TOTAL_VOLUME: 'Volume totale',
    NET_FUNDING: 'Paiement net',
    DEPOSITS: 'Dépôts',
    WITHDRAW: 'Retrait',
    OPEND_ACCOUNT: 'Compte ouvert',
    PERFORMANCE: 'Performance',
    ACCOUNT_MANAGER: 'Gestionnaire de compte',
    OpendAccount: 'Compte ouvert',
    MOST_TRADED_INSTRUMENTS: 'Les instruments les plus échangés',
    TOP_PERFORMING_ACCOUNTS_FOR_THIS_MONTH: 'Les comptes les plus performants de ce mois',
    RECENTLY_OPENED_ACCOUNTS: 'Comptes récemment ouverts',
    Account: 'Compte',
    Name: 'Nom',
    Volume: 'Volume',
    Rebate: 'Bonus',
    Balance: 'Équilibre',
    ContactClient: 'Contacter',
    YouCannotAskForCommission: 'Vous ne pouvez pas demander de bonus',
    applicationFailedPleaseTryAgain: "L'application a échoué, veuillez réessayer plus tard",
    applyRebateSuccessTip:
      "\n    Félicitations, nous avons reçu votre demande de remise. | {montant} sera transféré sur le compte de remise {compte} dans un délai d'un jour ouvrable. | Vous pouvez retirer ou transférer des fonds une fois votre remise traitée.",
    HOVER:
      'Les remises de la veille sont calculées et traitées quotidiennement entre 9:00 et 11:00 AEST.',
    blacklist:
      "Il n'est pas possible d'appliquer une remise à ce compte pour le moment. Contactez votre gestionnaire de compte.",
  },
  liveChat: { header: 'Chat en direct' },
  ibAccounts: { header: 'Comptes IB', ibAccount: 'Compte IB', subIbs: 'Compte de IB secondaire' },
  transferHistory: {
    payStatus: { processing: 'En cours', paid: 'Payé', unpaid: 'Non payé', rejected: 'Rejeté' },
  },
  withdraw: {
    disclaimer:
      '<li>Avis de non-responsabilité :</li>\n      <li>Veuillez noter que le taux de change pour la vente de crypto-monnaies contre des devises fiduciaires est déterminé uniquement par Banxa, un prestataire de paiement tiers. La marque Alphatick n’a aucune influence ni aucun contrôle sur le taux de change proposé par Banxa. Par conséquent, toute fluctuation ou modification du taux de change échappe à notre contrôle et est uniquement imputable aux politiques de Banxa et aux conditions du marché.</li>\n      <li>Nous vous recommandons de consulter le taux de change et tous les frais associés proposés par Banxa avant de poursuivre avec votre transaction. En outre, veuillez noter quAlphatick n’est pas responsable des pertes financières ou des divergences résultant de l’utilisation des services de Banxa ou de tout autre prestataire de paiement tiers.</li>\n      <li>Merci de votre compréhension et de votre coopération.</li>\n     ',
    default: {
      selectCC: 'Sélectionner une carte de crédit',
      selectBA: 'Sélectionner un compte en banque',
      anotherCC: 'Saisissez manuellement la carte de crédit',
      anotherBA: 'Ajouter un compte en banque',
      remember: 'Se souvenir de mon compte',
      failed: 'Échec du retrait. Veuillez réessayer plus tard',
      tokenExpired: 'Le jeton a expiré',
    },
    transfer: {
      info: '{platform} will reimburse the bank fee charged for <u>ONE</u> withdrawal per month. Additional withdrawals within the month will incur a bank fee of 20 units of your withdrawal currency.',
      label: {
        upload:
          "Veuillez télécharger une capture d'écran, une photo ou une copie numérisée de votre relevé bancaire (couvrant les 3 derniers mois)",
      },
      formValidation: { upload: 'Veuillez télécharger un relevé bancaire' },
    },
    alert: {
      title: 'Avant de soumettre votre demande de retrait, tenez compte des points suivants :',
      desc1:
        'En raison des lois AML, la méthode que vous utilisez pour retirer doit être la même que la méthode utilisée pour votre dépôt. Si le montant du retrait est inférieur à {units} unités de la devise de base de votre compte, des frais de traitement vous seront facturés {charge} unités par la banque.',
    },
    cryptoAlert:
      "Veuillez noter que nous ne sommes pas en mesure d'effectuer des dépôts ou des retraits via BUSDT. Assurez-vous que l'adresse et la crypto-monnaie correspondent à la chaîne et à la devise que nous acceptons, sinon vous pourriez perdre les fonds.",
    withdrawlFeeAlert:
      'Si le montant du retrait est inférieur à {units} unités de la devise de base de votre compte, des frais de traitement vous seront facturés {charge} unités par la banque.',
    REBATE_WITHDRAW_REQUEST: 'Demande de retrait de bonus',
    PleaseSelectDesiredWithdrawMethod: 'Veuillez sélectionner la méthode de retrait souhaitée',
    BankName: 'Nom de banque',
    bankCode: 'Code de la banque',
    bankBranchCode: 'Code de la succursale',
    BankAccountsName: 'Nom des comptes bancaires',
    FasaPayAccountName: 'Nom du compte FasaPay',
    AustralianBankName: 'Nom de la banque Australienne',
    BankAccountNumber: 'Numéro de compte bank',
    bankAccountCurrency: 'Devise du compte en banque',
    FasaPayAccountNumber: 'Numéro de compte FasaPay',
    BankAccountBeneficiary: 'Bénéficiaire du compte bancaire',
    BSB: 'BSB',
    skrillEmail: 'Email Skrill',
    NetellerEmail: 'Email Neteller',
    BitcoinEmail: 'Adresse du portefeuille Bitcoin',
    USDTEmail: 'Adresse du portefeuille Tether',
    perfectMoneyEmail: 'Email Perfect Money',
    BankBranch: 'Agence bancaire',
    Province: 'Province',
    City: 'Ville',
    BankAddress: 'Adresse de la banque',
    AccountNumberIBAN: 'Numéro de compte/IBAN',
    BankBeneficiaryName: 'Nom du bénéficiaire de la banque',
    BankAccountBeneficiaryName: 'Nom du bénéficiaire du compte bancaire',
    AccountHoldersAddress: 'Adresse du titulaire du compte',
    Swift: 'SWIFT',
    SwiftAud: 'SWIFT(Comptes non AUD)',
    ABA_SortCodeABA: 'ABA/Code de Sort',
    name: 'Nom',
    phone: 'Numéro de téléphone',
    address: 'Adresse',
    email: 'Adresse e-mail',
    ImportantNotes: 'Notes importantes',
    YourPaymentGreaterThanAvailableBalance: 'Votre paiement est supérieur au solde disponible',
    CannotUseMoreThanTwoDecimalValues:
      'Vous ne pouvez pas utiliser des nombres et des nombres négatifs avec plus de deux décimales',
    YourPaymentIsBeingProcessed: 'Votre demande de retrait a été soumise',
    Itcannotbe0: 'Ça ne peut pas être 0',
    amtLarger: 'Le montant ne peut pas être nul et doit être supérieur ou égal à ${minLimit}',
    blackList:
      "Malheureusement, vous n'êtes actuellement pas en mesure de soumettre une demande de retrait. Contactez-nous à {supportEmail} pour plus d'informations",
    NetellerChargeAlert: 'Neteller facture des frais de transaction de 2% pour chaque retrait.',
    FasaPayChargeAlert: 'FasaPay facture des frais de transaction de 0,5% pour chaque retrait.',
    SkrillChargeAlert: 'Skrill facture des frais de transaction de 1% pour chaque retrait.',
    status: {
      withdrawalSubmitted: 'Soumis',
      withdrawalCanceled: 'Annulé',
      withdrawalAuditing: 'En cours',
      withdrawalRejected: 'Rejeté',
      withdrawalAccepted: 'En cours',
      withdrawalPayFailed: 'Échec',
      withdrawalSuccess: 'Traité',
      withdrawalFailed: 'Échec',
      withdrawalPending: 'Pending',
      withdrawalPartialFailed: 'Échec',
      transferSubmitted: 'En cours',
      transferSuccess: 'Traité',
      transferRejected: 'Rejeté',
      transferFailed: 'Échec',
      transferProcessing: 'En cours',
      withdrawalReversedfailed: 'Échec inversé',
    },
    formErrorMsg: {
      bankName: 'Le nom de la banque est requis',
      bankCodeReq: 'Le code de la banque est requis',
      bankBranchCodeReq: 'Le code de la succursale est requis',
      bsb: 'BSB est requis',
      beneName: 'Le nom du bénéficiaire de la banque est requis',
      accNum: 'Le numéro de compte bancaire est requis',
      swift: 'Swift est requis',
      bankAddress: "L'adresse bancaire est obligatoire",
      holderAddress: "L'adresse du titulaire est obligatoire",
      amt: 'Un montant est requis',
      firstSixDigits: 'Veuillez entrer les 6 premiers chiffres',
      lastFourDigits: 'Veuillez entrer les 4 premiers chiffres',
      branchName: 'Le nom de la succursale est obligatoire',
      email: "L'email est requis",
      accName: 'Le nom du compte est obligatoire',
      province: 'La province de la banque est obligatoire',
      city: 'Banque Ville est requis',
      emailFormat: 'Veuillez entrer une adresse e-mail correcte',
      bitcoin: "L'adresse du portefeuille Bitcoin est requise",
      bitcoinAddressLength:
        "L'adresse du portefeuille en Bitcoin doit comporter entre 26 et 42 caractères",
      usdt: "L'adresse du portefeuille USDT est requise",
      dynamicReq: '{fieldname} est requis',
      beneficiaryNameReq: 'Le nom du bénéficiaire bancaire est requis',
    },
    beneficiaryName: 'Nom du bénéficiaire',
  },
  rebate: { status: { processed: 'Traité', processing: 'En traitement', rejected: 'Rejeté' } },
  transfer: {
    REBATE_TRANSFER_REQUEST: 'Demande de transfert de bonus',
    transferAmountIsGreaterThanAvailableBalance:
      'Le montant de votre transfert est supérieur au solde disponible',
    yourRebateTransferHasBeenProcessed: 'votre transfert de remise a été soumis',
    PleaseEnterTransferInformation: 'Veuillez saisir des informations de transfert précises',
    tradingAccount: 'Un compte de commerce',
    rebateAccount: 'Compte de bonus',
    account: 'Compte',
  },
  fileUploader: {
    supportedFormat: 'Types de fichier pris en charge : bmp, jpg, gif, jpeg, png, tiff, doc, pdf',
    supportedFormatCustom: 'Types de fichier pris en charge: {files}',
    maxSize: 'Taille maximale du fichier de téléchargement : {maxSize} Mo',
    maxFileNum: 'Nombre maximum de fichiers : {maxFileNum}',
    failedFileExtension: 'Veuillez télécharger le type de fichier correct',
  },
  common: {
    lang: {
      english: 'Anglais',
      chinese: 'Chinois',
      french: 'Français',
      thai: 'Thaïlandais',
      german: 'Allemand',
      spanish: 'Espagnol',
      malay: 'Malais',
      viet: 'Vietnamien',
      indo: 'Indonésien',
      arabic: 'Arabe',
    },
    button: { selectFile: 'Sélectionner un fichier' },
    withdrawChannel: {
      hongkongbanktransfer: 'Virement bancaire local à Hong Kong',
      banktransfer: 'Virement',
      aus: 'Virement bancaire - Australie',
      int: 'Virement bancaire - International',
      chn: 'Virement bancaire - Chine',
      skrill: 'Skrill',
      skrill_neteller: 'Skrill / Neteller',
      neteller: 'Neteller',
      unionpay: 'UnionPay',
      fasapay: 'FasaPay',
      thailandinstantbankwiretransfer: 'Virement bancaire instantané en Thaïlande',
      malaysiainstantbankwiretransfer: 'Virement bancaire instantané en Malaisie',
      indonesiainstantbankwiretransfer: 'Virement bancaire express Indonesia',
      philippinesinstantbankwiretransfer: 'Virement bancaire express Philippines',
      banktransferbpaypolipay: 'Virement/BPay/PoliPay',
      banktransferbpaypolipayaustralia: 'Virement/BPay/PoliPay (Australie)',
      banktransferbpaypolipayinternational: 'Virement/BPay/PoliPay (International)',
      cryptocurrencybitcoin: 'Crypto-monnaie-Bitcoin',
      cryptocurrencyusdt: 'Crypto-monnaie-USDT',
      vietnaminstantbankwiretransfer: 'Virement bancaire express vietnamien',
      laosinstantbankwiretransfer: 'Virement bancaire instantané au Laos',
      indiainstantbanktransfer: 'Virement bancaire express Inde',
      brazilbanktransfer: 'Virement bancaire express Brésil',
      mexicobanktransfer: 'Virement bancaire express Mexique',
      internetbankingmexico: 'Banque virtuelle (Mexique)',
      internetbankingbrazil: 'Banque virtuelle (Brésil)',
      transfer: 'Transfert aux comptes',
    },
    country: { international: 'International', australia: 'Australie' },
    field: { country_region: 'Pays/Région', yes: 'Oui', no: 'Non' },
    keys: {
      SUBMIT: 'Soumettre',
      FROM: 'De',
      UPDATE: 'Mise à jour',
      TO: 'À',
      DATE: 'Date',
      pleaseSelect: 'Veuillez sélectionner',
      amount: 'Montant',
      rebateAccount: 'Compte de bonus',
      comingSoon: 'bientôt disponible',
      tradingHistory: 'Histoire du commerce',
      noData: 'Pas de données',
      NAME: 'Nom',
      EMAIL: 'Email',
      PHONE: 'Numéro de téléphone',
      ACCTYPE: 'Type de compte',
      PLATFORM: 'Plate-forme',
      BASECURRENCY: 'Devise',
      DATERANGE: 'Plage de dates',
      STATUS: 'Statut',
      DEST: 'Destination',
      ACCNUM: 'Numéro de compte',
      BALANCE: 'Équilibre',
      NONE: 'Aucun',
      ALL: 'Tout',
      PROFIT: 'Profit',
      MARGINLEVEL: 'Niveau de marge (%)',
      ACCOUNTEQUITY: 'Fonds propres du compte',
      CREDIT: 'Crédit',
      createTime: 'Heure de soumission',
      lastUpdateTime: 'Temps traité',
    },
    dateRange: {
      today: "Aujourd'hui",
      yesterday: 'Hier',
      lastSevenDays: 'Les 7 derniers jours',
      lastThirtyDays: 'Les 30 derniers jours',
      weekToDate: 'À ce jour',
      monthToDate: 'Le mois courant',
      quarterToDate: 'Trimestre à ce jour',
      previousWeek: 'Semaine précédente',
      previousMonth: 'Le mois précédent',
      previousQuarter: 'Trimestre précédent',
    },
    products: {
      forex: 'Forex',
      commodities: 'Matières premières',
      indices: 'Indices',
      crypto: 'Crypto',
    },
  },
  report: {
    title: { rebateReport: 'Rapport de bonus' },
    rebate: {
      column: {
        name: 'Nom',
        accountNumber: 'Numéro du compte',
        accountType: 'Type de compte',
        volume: 'Volume',
        rebate: 'Bonus',
        totalRebate: 'Bonus totale',
        date: 'Date',
        instrument: 'Instrument',
        notionalvalue: 'Valeur Notionnelle',
      },
      dropdown: { allInstruments: 'Tous les instruments', all: 'Tous', instruments: 'Instruments' },
      others: {
        rebate: 'Bonus',
        instrumentsTraded: 'Instruments tradés',
        lots: 'Lots',
        totalRebate: 'Bonus totale',
        rebates: 'Bonus',
      },
    },
  },
  ibReport: {
    header: 'Rapport IB',
    netFunding: 'Paiement net',
    deposits: 'Dépôts',
    withdraw: 'Retrait',
    openedAccs: 'Comptes ouverts',
    funding: 'Paiement',
  },
  lead: { header: 'Opportunites', ibAccount: 'Compte IB', demoAccounts: 'Comptes Démo' },
  pendingAccounts: {
    header: 'Comptes en attente',
    errorDoc:
      "Les demandeurs suivants ont rempli le formulaire de demande en ligne, mais doivent encore fournir un justificatif d'identité afin de finaliser la création de leur compte.",
    acceptedDocType: "Les pièces d'identité acceptées comprennent:",
    primaryDoc: "Pièce d'identité principale",
    idList: [
      'Une copie de votre passeport international valide (page de signature)',
      "Une copie de votre carte d'identité nationale délivrée par l'administration",
      'Une copie de votre permis de conduire valide',
    ],
    address: "Autre pièce d'identité",
    addressList: [
      "Une copie de facture de services publics récente. Par exemple, une facture de gaz ou d'électricité mentionnant votre nom et l'adresse de votre résidence (datant de moins de 6 mois)",
      "Une copie de votre relevé de carte de crédit ou de votre relevé bancaire (pas d'impression de relevé en ligne)",
    ],
    tip: "Veuillez inviter les demandeurs à fournir une copie d'UN des justificatifs d'identité principaux et autres pour {mailTo}",
  },
  rebatePaymentHistory: {
    transferHistory: 'Historique des transferts',
    rebateHistory: 'Historique des bonus',
    withdrawHistory: 'Historique des retraits',
  },
  referralLinks: {
    header: 'Liens de référence',
    ibAcc: "Compte de l'apporteur d'affaires",
    campLang: 'Langue de la campagne',
    cpy: 'Copier',
    alreadyCPY: 'Copié!',
    liveAcc: 'Compte en direct',
    demoAcc: 'Compte démo',
    homePage: "Page d'accueil",
  },
  settings: {
    header: 'Informations client',
    name: 'Nom',
    emailAdd: 'Adresse e-mail',
    phone: 'Numéro de téléphone',
    address: 'Adresse',
  },
  unfundedAcc: {
    header: 'Comptes non approvisionnés',
    neverFundedAccs: 'Comptes jamais approvisionnés',
    prevFundedZero: 'Précédemment approvisionné (Solde nul)',
    accBalance: 'Solde du compte',
  },
  multiib: { level: 'Niveau {level}', noib: 'Aucun sous-CR dans ce compte.' },
  header: { bckToCP: 'Retour au Portail Client', logout: 'Déconnexion' },
  menu: {
    dashboard: 'Tableau de bord',
    ibReport: 'Rapport IB',
    rebateReport: 'RAPPORT DE BONUS',
    iblevel: 'Cr multi-niveau',
    ibAccs: 'Comptes IB',
    leads: 'Nouveaux clients',
    pendingAccs: 'Comptes en attente',
    unfundedAccs: 'Comptes non approvisionnés',
    payHistory: 'Historique des Transactions',
    ibProfile: 'Profil IB',
    refLinks: 'Liens de référence',
    contactUs: 'Nous contacter',
    FEATURES: 'Fonctionnalité',
  },
  subIb: {
    title: 'Compte IB partagé',
    account: 'Compte',
    accountNote: 'Maximum 10 comptes IB partagés',
    subCreateAcc: 'Ajouter',
    subCreateIbAcc: 'Ajouter un nouveau compte IB partagé',
    editAccount: 'Modifier le compte IB partagé',
    copied: 'Compte IB partagé copié',
    no: 'Nombre',
    permission: 'Permission',
    remark: 'Remarque',
    status: 'Statut',
    confirm: 'Confirmer',
    cancel: 'Annuler',
    noPermission: 'Pas de permission pour ce compte',
    setting: 'Paramètres',
    viewMore: 'Voir plus',
    editPassword: 'Réinitialiser le mot de passe',
    resetPassword: 'Veuillez saisir le nouveau mot de passe',
    delete: 'Supprimer',
    emailAddress: 'Adresse e-mail',
    emailFormat: 'Veuillez saisir une adresse e-mail valide',
    permissionTooltip:
      "Vous pouvez autoriser ou non les permissions suivantes pour les comptes partagés :<li>• Données des comptes : Afficher les pages des données du compte (Comptes IB, Comptes récemment ouverts). Les données seront masquées si l'autorisation n'est pas autorisée. </li><li>• Données du rapport IB : Afficher les pages des données IB (Tableau de bord, IB multi-niveaux - Compte). Les données seront masquées si l'autorisation n'est pas autorisée. </li><li>• Autorisation des fonds : Opérer sur les fonds IB (Demander un remboursement, Transférer un remboursement, Retirer un remboursement), Afficher la remise (rapport sur les remises, enregistrements de solde et de fonds). Les opérations ne peuvent pas être effectuées si l'autorisation n'est pas autorisée. </li>",
    refresh: 'Actualiser',
    historyEnd: "Tout l'historique a été affiché",
    refreshTips: 'Historique mis à jour',
    password: 'Mot de passe',
    isActive: 'Activé',
    updatedTime: 'Dernière mise à jour',
    accWarning: "10 comptes existent, impossible d'en ajouter.",
    isInactive: 'Désactivé',
    passwordTooltip:
      '<li>• 8 à 16 caractères</li><li>• Mélange de lettres majuscules et minuscules (A-Z, a-z), de chiffres (0-9) et de caractères spéciaux (par exemple : !@#S%^&)</li>',
    resetPasswordTooltip:
      "L'ancien mot de passe sera invalide et réinitialisé avec le nouveau mot de passe",
    all: 'Tout',
    chooseAccount: 'Choisir le compte',
    permissionField: 'Permission du compte IB partagé',
    historyTitle: 'Historique du compte partagé',
    device: 'Appareil',
    emailExisting: "L'e-mail existe déjà, veuillez en essayer un autre",
    remarkPlaceholder: 'Vous pouvez enregistrer le but du compte ou le titulaire du compte',
    passwordRequired: 'Le mot de passe est obligatoire.',
    deleteDesc:
      'Le compte IB partagé ne pourra pas se connecter après sa suppression, voulez-vous le supprimer ?',
    updateWithdrawalDesc:
      "Une fois l'autorisation des fonds accordée, le compte IB commun peut transférer et retirer le solde de votre compte IB principal. Souhaitez-vous autoriser cela ?",
    loginAction: 'Se connecter',
    applyRebateAction: 'Demander le remboursement',
    deleteSuccess: 'Le compte IB partagé a été supprimé.',
    enabledSuccess: 'Le compte IB partagé a été activé.',
    disabledSuccess: 'Le compte IB partagé a été désactivé.',
    addSuccess: 'Compte commun créé',
    modifiedSuccess: 'Modifié avec succès',
    withdrawRebateAction: 'Retrait du remboursement : De {from} à {to}, montant de {amount}',
    passwordSuccess: 'Réinitialisation du mot de passe réussie',
    transferRebateAction: 'Transfert du remboursement : De {from} à {to}, montant de {amount}',
    newPwAccFormat:
      'Votre mot de passe doit contenir entre 8 et 16 caractères et une combinaison de lettres (a-z et A-Z), de chiffres (0-9) et de caractères spéciaux tels que !@#$%^&*',
    clientData: 'Données des comptes',
    ibData: 'Données du rapport IB',
    fundsPermission: 'Autorisation des fonds',
  },
  footer: {
    riskWarn:
      'Attention: Le trading de Forex et CFDS implique d’importants risques et peut entrainer la perte de votre investissement. Le trading de produits financiers avec l’aide d’effets de levier n’est recommandé que pour des investisseurs avertis. Avant de trader, vous devriez prendre en considération votre niveau d’expérience, vos objectifs d’investissement et chercher l’avis financier d’indépendants si besoin. Veuillez lire nos règles juridiques sur notre site internet afin de comprendre pleinement les risques encourus avant de commencer à trader.',
  },
  responseMsg: {
    410: 'Échec de la validation du paramètre',
    411: 'Mot de passe incorrect',
    420: "L'utilisateur n'existe pas",
    421: 'Réinitialisation du mot de passe refusée',
    490: 'Ne soumettez pas les informations de votre carte en double',
    500: "Une erreur s'est produite.",
    501: 'Échec de la demande. Veuillez réessayer plus tard.',
    505: 'Échec du téléchargement du fichier',
    520: 'Échec de connexion. Veuillez réessayer.',
    521: 'Un utilisateur est déjà connecté sur cet appareil !',
    522: 'Veuillez vous reconnecter',
    523: 'Veuillez vous reconnecter',
    524: "Malheureusement votre adresse e-mail n'a pas été reconnue",
    525: "Nom d'utilisateur ou mot de passe incorrect",
    527: "L'utilisateur n'est pas un apporteur d'affaires",
    528: "L'utilisateur n'existe pas",
    529: "Nom d'utilisateur ou mot de passe incorrect",
    540: 'Votre compte de trading est introuvable',
    541: 'Compte de bonus introuvable',
    542: "Vous n'êtes pas autorisé à ouvrir un compte supplémentaire",
    544: "Vous n'êtes pas autorisé à demander un compte supplémentaire tant que votre pièce d'identité n'a pas été vérifiée",
    550: 'Numéro de compte incorrect',
    551: 'Votre solde est insuffisant',
    553: "Vous ne pouvez pas retirer / transférer en raison du crédit sur votre compte. Veuillez contacter {email} pour obtenir de l'aide",
    554: 'La somme demandée est égale à zéro ou est nulle',
    564: 'Le pays ne correspond pas au canal de paiement',
    565: 'Code de banque incorrect',
    566: 'Méthode de paiement introuvable',
    581: 'Vous avez bien choisi la promotion. Veuillez noter que vous devez effectuer un dépôt pour retirer des fonds.',
    584: "Vous n'avez aucune bonus applicable",
    590: 'Compte du transfert sortant introuvable',
    591: 'Compte du transfert entrant introuvable',
    593: 'Nous ne sommes pas en mesure de traiter votre transfert de fonds depuis le compte sélectionné car votre compte contient du crédit.',
    594: "Le transfert de fonds depuis ce compte n'est pas autorisé",
    1101: "10 comptes existent, impossible d'en ajouter.",
    1102: "Impossible d'activer, le compte a été créé en tant que compte réel ou démo.",
    session_timeout: 'Expiration de la session. Redirection vers la page de connexion.',
  },
};
