import { polyfill } from 'es6-promise'
import '@/util/polyfill'

import Vue from 'vue'
import App from './App'
import './registerServiceWorker'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition'
import VueClipboard from 'vue-clipboard2'
import Vue2Filters from 'vue2-filters'
import store from './store'
import '@/util/common'
import '@babel/polyfill'
import directives from '@/util/directives'
import { handleError } from '@/util/handleError'
import '@/util/filter'
import config from './config'
import '@/mixins/global'
import Storage from 'vue-ls'
import InputForm from '@/components/form/Input'
import SelectForm from '@/components/form/Select'

const options = {
  namespace: 'vuejs__', // key键前缀
  name: 'ls', // 命名Vue变量.[ls]或this.[$ls],
  storage: 'local', // 存储名称: session, local, memory
}

Vue.use(Storage, options)

// 国际化
import i18n from '@/lang'

import echarts from 'echarts/lib/echarts'
// 引入折线图和饼图
import('echarts/lib/chart/line')
import('echarts/lib/chart/pie')
import('echarts/lib/component/legendScroll')
// 引入提示框和标题组件
import('echarts/lib/component/tooltip')
import('echarts/lib/component/title')
import('echarts/lib/component/dataZoom')
import('echarts/lib/theme/dark')
import('echarts/lib/theme/light')
import moment from 'moment'

//引入公共css样式
import '@/assets/css/global/reset.scss'
import '@/assets/css/global/element.scss'
import '@/assets/css/global/public.scss'

Vue.component(CollapseTransition.name, CollapseTransition)
Vue.component('InputForm', InputForm)
Vue.component('SelectForm', SelectForm)

for (let key in directives) {
  Vue.directive(key, directives[key])
}

Vue.use(ElementUI)
Vue.use(Vue2Filters)
Vue.use(VueClipboard)

Vue.prototype.$config = config
Vue.prototype.echarts = echarts
Vue.prototype.$moment = moment
Vue.prototype.bus = new Vue()
Vue.prototype.$handleError = handleError

Vue.config.productionTip = false

new Vue({
  el: '#app',
  i18n,
  store,
  router,
  components: {
    App,
  },
  template: '<App/>',
})
