// 進行同步執行
import Vue from 'vue'
import { defaultVariableMapping } from '@/components/profile/SubProfile.js'

export default {
  setLang(state, value) {
    state.lang = value
  },
  setLoginStatus(state, value) {
    state.loginStatus = value
  },
  setUserName(state, value) {
    Vue.ls.set('setUserName', value)
    state.userName = value
  },
  setEmail(state, value) {
    state.email = value
  },
  setPhone(state, value) {
    state.phone = value
  },
  setCreateTime(state, value) {
    state.createTime = value
  },
  setAddress(state, value) {
    state.address = value
  },
  setCpUrl(state, value) {
    state.cpUrl = value
  },
  setAccessToken(state, value) {
    state.accessToken = value
  },
  setCountryCode(state, value) {
    state.countryCode = value
  },
  setRegulator(state, value) {
    state.regulator = value
  },
  setAutoNotifyStatus(state, value) {
    state.autoNotifyStatus = value
  },
  setIsSubUser(state, value) {
    state.isSubUser = value
  },
  setSubPermission(state, value) {
    state.subPermission = value
    state.customerDataPermission = !state.isSubUser || value.includes(defaultVariableMapping['clientData'])
    state.ibDataPermission = !state.isSubUser || value.includes(defaultVariableMapping['ibAccount'])
    state.operateAmountPermisison = !state.isSubUser || value.includes(defaultVariableMapping['depositWithdrawal'])
  },
  setSubUserSwitch(state, value) {
    state.subUserSwitch = value
  },
  setSubEmail(state, value) {
    state.subEmail = value
  },
  reset(state) {
    const initial = state
    Object.keys(initial).forEach(key => {
      if (key == 'userName') Vue.ls.set('setUserName', '')
      if (key != 'CUID' && key != 'lang') state[key] = ''
    })
  },
  setSessionId(state, value) {
    state.sessionId = value.sessionId
    state.sessionInternalId = value.sessionInternalId
  },
}
