// 進行異步執行
import { apiInformation } from '@/resource'

export default {
  async actionGetInformation({ commit, state }) {
    return new Promise((resolve, reject) => {
      apiInformation().then(result => {
        if (result.data.domainWebSite) {
          commit('setDomainWebSite', result.data.domainWebSite)
          commit('setDomainWebSiteCn', result.data.domainWebSiteCn)
          commit('setDomainWebSiteId', result.data.domainWebSiteId)
          commit('setContactEmail', result.data.contactEmail)
          commit('setAccountEmail', result.data.accountEmail)
        } else {
          console.log('error')
        }
      })
    })
  },
}
