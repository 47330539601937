import Vue from 'vue'
import VueI18n from 'vue-i18n'
import ElementLocale from 'element-ui/lib/locale'
import elementEnLocale from 'element-ui/lib/locale/lang/en' // element-ui lang
import elementFrLocale from 'element-ui/lib/locale/lang/fr'
import elementEsLocale from 'element-ui/lib/locale/lang/es'
import elementPtLocale from 'element-ui/lib/locale/lang/pt'
import elementDeLocale from '@/lang/element-ui/de.js'

import enLocale from './at/en'
import frLocale from './at/fr'
import esLocale from './at/es'
import ptLocale from './at/pt'
import deLocale from './at/de'

Vue.use(VueI18n)

const messages = {
  en_US: {
    ...enLocale,
    ...elementEnLocale,
  },
  fr_FR: {
    ...frLocale,
    ...elementFrLocale,
  },
  es: {
    ...esLocale,
    ...elementEsLocale,
  },
  pt: {
    ...ptLocale,
    ...elementPtLocale,
  },
  de: {
    ...deLocale,
    ...elementDeLocale,
  },
}

const i18n = new VueI18n({
  messages, // set locale messages
  fallbackLocale: 'en_US',
})

ElementLocale.i18n((key, value) => i18n.t(key, value))

export default i18n
