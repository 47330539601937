import { endAjaxLoading } from '@/util/loading'
import { Message } from 'element-ui'
import i18n from '@/lang/index'
import helper from '@/util/signinHelper'
import { setCookies, getCookies } from '@/util/cookies'
import config from '@/config'
import store from '@/store'
import { HTMLdecode } from '@/util/utils';
import { FILTER_URLS } from '@/constants/filterUrls.js';
function respHandler(resp) {
  endAjaxLoading()

  //if response is session_timeout, logout.
  if (resp.data.code === 'session_timeout') helper.signOut('logout')
  else {
    //if response isn't session_timeout, then update token.
    const token = getCookies('token')
    setCookies('token', token, 30)
  }

  const toIgnore = [0, 1500]

  if (resp.data.hasOwnProperty('code') && !toIgnore.includes(resp.data.code)) {
    let respMsg = getResponseMsg(resp.data.code)
    if (resp.data.code === 580) {
      respMsg = getWithdrawalBlacklist()
      Message({
        message: respMsg,
        type: 'error',
      })
    }
    //if response code: 5xx, show error msg
    else if (resp.data.code >= 500) {
      Message({
        message: respMsg,
        type: 'error',
      })
    }
    //if response code: 4xx, show warning msg
    else if (resp.data.code >= 400) {
      Message({
        message: respMsg,
        type: 'warning',
      })
    }
  }
  if (resp.data && FILTER_URLS) {
    for (let i = 0; i < FILTER_URLS.length; i++) {
      if (resp.config.url.includes(FILTER_URLS[i])) {
        resp.data = HTMLdecode(resp.data)
        break;
      }
    }
  }
  return resp
}
function getResponseMsg(code) {
  const email = store.state.regulator.contactEmail || config.info.getEmail(store.state.common.regulator)
  return i18n.t('responseMsg.' + code, { email: email })
}
function getWithdrawalBlacklist() {
  const supportEmail = config.info.getWithdrawalEmail(store.state.common.regulator)
  return i18n.t('withdraw.blackList', { supportEmail: supportEmail })
}
export default respHandler
