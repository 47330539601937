import Vue from 'vue'

Vue.mixin({
  computed: {
    regulator() {
      return this.$store.state.common.regulator
    },
    GLOBAL_DOMAIN_WEBSITE() {
      console.log(this.$store.state.regulator.domainWebSite)
      console.log(this.$config.info.getUrl(this.regulator))

      return this.$store.state.regulator.domainWebSite || this.$config.info.getUrl(this.regulator)
    },
    GLOBAL_DOMAIN_WEBSITECN() {
      return this.$store.state.regulator.domainWebSiteCn || this.$config.info.getUrl2(this.regulator)
    },
    GLOBAL_DOMAIN_WEBSITEID() {
      return this.$store.state.regulator.domainWebSiteId || this.$config.info.getUrl3(this.regulator)
    },
    GLOBAL_DOMAIN_WEBSITEIT() {
      return this.$store.state.regulator.domainWebSiteIt || this.$config.info.getUrl4(this.regulator)
    },
    GLOBAL_DOMAIN_WEBSITEES() {
      return this.$store.state.regulator.domainWebSiteEs || this.$config.info.getUrl5(this.regulator)
    },
    GLOBAL_CONTACT_EMAIL() {
      // return this.$store.state.regulator.contactEmail || this.$config.info.getEmail(this.regulator)
      return this.$config.info.getEmail(this.regulator)
    },
    GLOBAL_WITHDRAWAL_EMAIL() {
      return this.$config.info.getWithdrawalEmail(this.regulator)
    },
  },
  methods: {
    // showLiveChat() {
    //   // zE('webWidget', 'open')
    //   console.log('客服AI暂未完善')
    // },
  },
})
