const i18n = require('@/lang/index')

module.exports = {
  info: {
    url: 'www.vtmarkets.com',
    url2: 'www.vtmarkets-zh.com',
    url3: 'www.vtmarketsindo.com',
    map:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3765.638612031567!2d-81.38410268509537!3d19.29807578696095!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f25865f22a0eebd%3A0xcd06467def56ae29!2sThe Harbour Centre%2C 42 N Church St%2C George Town%2C Cayman Islands!5e0!3m2!1sen!2sau!4v1571113930600!5m2!1sen!2sau',
    address: `<span>4th Floor The Harbour Centre</span><br>
            <span> 42 N Church St</span><br>
            <span>George Town, Cayman Islands</span>`,
    name: 'Vantage International Group Limited',
    email: 'info@vtmarkets.com',
    withdrawalEmail: 'withdrawal@vtmarkets.com',
  },
}
